<h2>Мониторинг действующих договоров школы с другими организациями</h2>
<div class="panel-monitoring">
  <p><a href="javascript:void(0)" (click)="showAllHandler()"><h4>Выбрать колонки для отображения</h4></a></p>
  <button type="button" (click)="openModal(add)" style="float: right; margin-top: -29px;" class="btn btn-success top-item"><span class="glyphicon glyphicon-plus"></span> Зарегистрировать новый договор</button>&nbsp;
</div>



<div *ngIf = "showAll">

    <div class="columns">
      <div *ngFor="let column of columns"> 
        <p><input type="checkbox" [(ngModel)]="column.visibility">{{column.name}}</p>
      </div>
    </div>

</div>

<table class="table">
  <thead>
    <tr>
      <th *ngFor = "let column of getVisibleColumns()" scope="col">{{column.name}}</th>
      <th scope="col">Действия</th>
    </tr>
    
    <tr *ngFor="let contract of contract.contracts">
      <td *ngFor="let column of getVisibleColumns()">{{getPropertyValue(contract, column.variable)}}</td>
      <td>
        <div class="action-icons">
          <a title="Редактировать" href="javascript:void(0)"  (click)="copy(contract); openModal(edit)"><span style="color: purple" class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
          <a title="Удалить" href="javascript:void(0)" (click)="delete(getPropertyValue(contract, 'id'))"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
        </div>
      </td>
    </tr>
  </thead>
</table>

<ng-template #add>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Зарегистрировать новый договор</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px" class="field-box">
      <div>
        Организация:
        <ng-select [items]="organizations"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="contractToCreate.organization.id">
          </ng-select>
      </div>
      <div>
        № договора:
        <input type="text" [(ngModel)]="contractToCreate.number" class="form-control" placeholder="№ договора">
      </div>
      <div>
        Дата подписания, срок действия:
        <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" 
        [(ngModel)]="contractToCreate.signingDate" AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
      </div>
      <div>
        Предмет договора:
        <input type="text" [(ngModel)]="contractToCreate.subject" class="form-control" placeholder="Предмет договора">
      </div>
      <div>
        Кем подписан:
        <ng-select [items]="users"
                   bindLabel="fullName"
                   bindValue="id"
                   [(ngModel)]="contractToCreate.signedBy.id">
          </ng-select>
      </div>
      <div>
        Сумма договора:
        <input type="number" [(ngModel)]="contractToCreate.sum" class="form-control" step="0.01" min="0" placeholder="0,00">
      </div>
      <div>
        Статус:
          <ng-select [items]="statuses"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="contractToCreate.status">
          </ng-select>
      </div>
      <div>
        Дата контроля:
        <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" 
        [(ngModel)]="contractToCreate.controlDate" placement="top" AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
      </div>
      <div>
        Комментарий:
        <textarea [(ngModel)]="contractToCreate.comment" class="form-control" placeholder="Комментарий"></textarea>
      </div>
    </div>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="createContract()" class="btn btn-success">Зарегистрировать</button>
    </div>
  </div>
</ng-template>

<ng-template #edit>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Изменить договор</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px;">
      <div>
        Организация:
        <ng-select [items]="organizations"
                   bindLabel="name"
                   [(ngModel)]="newContract.organization">
          </ng-select>
      </div>
      <div>
        № договора:
        <input type="text" [(ngModel)]="newContract.number" class="form-control" placeholder="№ договора">
      </div>
      <div>
        Дата подписания, срок действия:
        <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" 
        [(ngModel)]="newContract.signingDate" AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
      </div>
      <div>
        Предмет договора:
        <input type="text" [(ngModel)]="newContract.subject" class="form-control" placeholder="Предмет договора">
      </div>
      <div>
        Кем подписан:
        <ng-select [items]="users"
                   bindLabel="fullName"
                   [(ngModel)]="newContract.signedBy">
          </ng-select>
      </div>
      <div>
        Сумма договора:
        <input type="number" [(ngModel)]="newContract.sum" class="form-control" step="0.01" min="0" placeholder="0,00">
      </div>
      <div>
        Статус:
          <ng-select [items]="statuses"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="newContract.status">
          </ng-select>
      </div>
      <div>
        Дата контроля:
        <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" 
        [(ngModel)]="newContract.controlDate" placement="top" AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
      </div>
      <div>
        Комментарий:
        <textarea [(ngModel)]="newContract.comment" class="form-control" placeholder="Комментарий"></textarea>
      </div>
      <div style="text-align:center; margin-top: 15px;">
        <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
        <button type="button" (click)="saveContract()" class="btn btn-success">Сохранить</button>
      </div>
    </div>
  </div>
</ng-template>

<p-toast></p-toast> 

