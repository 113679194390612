export enum Registration{
    notRegistered,
    intraschoolRegistration,
    municipalRegistration
}

export const registrations: any[] = [
  { id: 0, name: 'Не состоит на учете' },
  { id: 1, name: 'Внутришкольный учет' },
  { id: 2, name: 'Муниципальный учет' }
];