<h1>Тайм-лист</h1>

<div class="panel panel-default">
    <div class="panel-body">
        <div class="top">

            <div style="float:left;">
                <input type="text"
                       class="form-control top-item"
                       [(ngModel)]="range"
                       (ngModelChange)="loadData()"
                       AUTOCOMPLETE="off"
                       [bsConfig]="rangeBsConfig"
                       bsDaterangepicker
                       placeholder="Выбрать период"
                       style="width: 200px; float: left;"> &nbsp;

                <!-- <button type="button" (click)="openModal(add)" class="btn btn-success top-item"><span class="glyphicon glyphicon-plus"></span> Добавить запись</button>&nbsp; -->
                <!-- <button pButton pRipple type="button" label="Добавить запись" class="p-button-raised" (click)="openModal(add)"></button> -->
                <button pButton pRipple icon="pi pi-plus" type="button" label="Добавить запись" class="p-button-success" (click)="openModal(add)"></button>
            </div>  

            <div style="float:right;" class="top-right-btns">
                <button 
                    pButton 
                    pRipple 
                    *ngIf="userState.assignedTickets.state && userState.assignedTickets.state.length > 0"
                    icon="pi pi-bell" 
                    type="button" 
                    class="p-button-help"
                    badge="{{userState.assignedTicketCount.state}}"
                    label="Новые задания" 
                    (click)="openModal(assignedTasksModal)">
                </button>

                <!-- <button *ngIf="userState.assignedTickets.state && userState.assignedTickets.state.length > 0" type="button" (click)="openModal(assignedTasksModal)" class="btn btn-warning top-item"><span class="glyphicon glyphicon-bell"></span> Новые задания ({{userState.assignedTicketCount.state}})</button> -->
                <p-button 
                    label="Отчет" 
                    title="Сформировать отчет по тайм-листу"
                    (click)="getDocument()"
                    icon="pi pi-print">
                </p-button>
                <p-button 
                    label="Сводка" 
                    title="Открыть сводку"
                    (click)="openModal(report)"
                    icon="pi pi-list">
                </p-button>
                <!-- <button title="Сформировать отчет по тайм-листу" type="button" (click)="getDocument()" class="btn btn-primary top-item"><span class="glyphicon glyphicon-file" aria-hidden="true"></span></button>&nbsp; -->
                <!-- <button title="Открыть сводку" type="button" (click)="openModal(report)" class="btn btn-primary top-item"><span class="glyphicon glyphicon-th-list" aria-hidden="true"></span></button>&nbsp; -->
                <!-- <button title="Отправить там-лист на мою почту" type="button" (click)="sendTimeline()" class="btn btn-info top-item"><span class="glyphicon glyphicon-envelope" aria-hidden="true"></span></button>&nbsp; -->
            </div>


        </div>

        <div style="margin-top: 60px;width: 305px;">
            <input type="checkbox" id="cto" [(ngModel)]="checklistTicketsOnly" (ngModelChange)="checklistTicketsFilterHandler()">
            <label style="font-weight: normal;" for="cto">Показать только назначенные задания</label>
        </div>
    </div>
</div>

<div *ngIf="!packs" style="text-align: center; margin-top: 50px; font-style: italic;"><span class="loading">Загрузка</span></div>

<div style="text-align: center; margin-top: 70px; font-style: italic;" *ngIf="packs?.length == 0"><span>Выберите период</span></div>

<div *ngFor="let pack of packs">
    <div class="date-box">
        <div style="float:left;"><h3>{{pack.dateToShow}}</h3> </div> 
        <!-- <button (click)="openModal(add, pack.date)" pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-text"></button> -->
        <button pButton (click)="openModal(add, pack.date)" pRipple type="button" icon="pi pi-plus" class="p-button-rounded"></button>
        <!-- <button title="Добавить запись на этот день" class="btn btn-success add-date-btn" style="margin: 16px;" type="button" (click)="openModal(add, pack.date)"><span class="glyphicon glyphicon-plus"></span></button>&nbsp; <br /> -->
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col" style="width: 5%">Время</th>
                <th scope="col" style="width: 40%">Наименование</th>
                <th scope="col" width="34%">Комментарий</th>
                <th scope="col" width="11%"></th>
            </tr>
        </thead>
        <tbody *ngFor="let timeGroup of pack?.timeGroups">
            <tr><td style="font-weight: 700; font-size:larger" colspan="6">{{timeGroup.hour}}:00 </td></tr>
            <tr *ngFor="let ticket of timeGroup.tickets" [class.important]="ticket.important">
                <td><span *ngIf="ticket.hours && ticket.minutes != 0">{{ticket.hours}}:{{ticket.minutes}}<span *ngIf="ticket.minutes == 0">0</span></span></td>


                <td [class.done]="ticket.done"
                    title="Задание из чек-листа">
                    <a *ngIf="ticket.hasChecklist && !ticket.action" href="javascript:void(0)" (click)="openReplyModal(ticket, replyModal)" class="blue-text" [class.red-text]="ticket.isExpiered">
                        <span class='glyphicon glyphicon-check'></span>
                        {{ticket.name}} <br/>
                        <span class="badge" style="background-color: #83bbe2 !important; font-size: smaller;" *ngIf="ticket.onCheck && ticket.incomingDocument && ticket.incomingDocument.user">{{ticket.incomingDocument.user.fullName}}</span> 
                        <span class="badge" style="background-color: #83bbe2 !important; font-size: smaller;" *ngIf="ticket.onCheck && ticket.outgoingDocument && ticket.outgoingDocument.user">{{ticket.outgoingDocument.user.fullName}}</span> 
                    </a> 
                    <span *ngIf="!ticket.hasChecklist && !ticket.action">
                        {{ticket.name}} <br/>
                        <span class="badge" style="background-color: #83bbe2 !important; font-size: smaller;" *ngIf="ticket.onCheck && ticket.incomingDocument && ticket.incomingDocument.user">{{ticket.incomingDocument.user.fullName}}</span> 
                        <span class="badge" style="background-color: #83bbe2 !important; font-size: smaller;" *ngIf="ticket.onCheck && ticket.outgoingDocument && ticket.outgoingDocument.user">{{ticket.outgoingDocument.user.fullName}}</span> 
                    </span>
                    <div *ngIf="ticket.action" [class.deleted-action]="ticket.action.isDeleted">
                        <div class="inner-action-card c{{ticket.action.activity.color}}">
                            <span>
                                <span *ngIf="ticket.action.isDeleted" style="color: red; font-weight:600 ">Мероприятие удалено! <br /></span>
                                <span *ngIf="ticket.actionIsOutOfDate && ticket.action.workSchedule.user.id != userState.currentUser.state.id" style="color: red; font-weight:600 ">Это мероприятие находится не в вашем плане и дата вашей записи не соответствует дате этого мероприятия ({{ticket.action.date | date : 'dd.MM.yyyy'}}) <br /></span>
                                {{ticket.action.name}}
                            </span>
                            <br />
                            <span *ngFor="let user of ticket.action.responsibles" class="badge">{{user.lastName}} {{user.firstName[0]}}. {{user.surName[0]}}</span>
                            <br />
                            ФП: {{ticket.action.confirmationForm.name}}
                        </div>
                    </div>
                </td>
                <td [class.done]="ticket.done">
                    <span style="opacity: 0.7" *ngIf="ticket.autoGenerated && !ticket.comment">
                        Эта запись была создана автоматически после того, как вы добавили мероприятие в свой план
                    </span>
                    <span *ngIf="ticket.comment" (click)="copy(ticket); openFullCommentModal()" style="cursor: pointer;">
                        {{getCutComment(ticket.comment)}}
                    </span>
                </td>
                <td>
                    <div class="action-icons" style="text-align: center">
                        <a title="Отметка &laquo;Выполнено&raquo;" href="javascript:void(0)" (click)="makeDone(ticket)"><span [class.gray]="!ticket.done" class="glyphicon glyphicon-ok" aria-hidden="true"></span></a>
                        <a title="Отметка &laquo;Важно&raquo;" href="javascript:void(0)" (click)="makeImportant(ticket)"><span [class.gray]="!ticket.important" class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span></a>
                        <a title="Редактировать" href="javascript:void(0)" (click)="copy(ticket); openEditModal(edit)"><span style="color: steelblue" class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
                        <a title="Удалить" href="javascript:void(0)" (click)="delete(ticket)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #report>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Сводка</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div *ngFor="let pack of packs">
            <h5>{{pack.dateToShow}}</h5>
            <div *ngFor="let timeGroup of pack?.timeGroups">
                <ul>
                    <li *ngFor="let ticket of timeGroup.tickets" [class.done-report]="ticket.done" [class.undone-report]="!ticket.done" style="margin-bottom: 10px; matgin-top: 10px;">
                        <span *ngIf="!ticket.action">{{ticket.name}}</span>
                        <span *ngIf="ticket.action && ticket.name">{{ticket.name}} в рамках мероприятия "{{ticket.action.name}}"</span>
                        <span *ngIf="ticket.action && !ticket.name">{{ticket.action.name}}</span>
                    </li>
                </ul>
            </div>
            <br />
        </div>

    </div>
</ng-template>

<ng-template #replyModal>
    <div class="modal-header">
        <h4 style="width: 95%; font-weight: bold;" class="modal-title pull-left">{{checklistTicketToReply.name}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <p>Чек-лист: &laquo;{{checklistTicketToReply.checklist.name}}&raquo; <br /> Автор: {{checklistTicketToReply.checklist.user.fullName}}</p>
            <br />
            <span style="font-size: 15px; font-weight: bold;">Комментарий и вложения автора:</span><br />
            <span>{{checklistTicketToReply.comment}}</span>
            <div *ngIf="checklistTicketToReply.inFiles.length == 0" style="text-align: center; margin-top: 25px; font-style: italic;"><span>Автор задания пока не оставил ни одного вложения</span></div>
            <table class="table" *ngIf="checklistTicketToReply.inFiles.length != 0">
                <thead>
                    <tr>
                        <th scope="col" style="width: 80%">Файл</th>
                        <th scope="col" style="width: 10%">Размер</th>
                        <th scope="col" style="width: 10%; text-align: center">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let file of checklistTicketToReply.inFiles">
                        <td>
                            <i *ngIf="file.extension == 'doc' || file.extension == 'docx'" class="fa fa-file-word-o" aria-hidden="true" style="color: blue"></i>
                            <i *ngIf="file.extension == 'xls' || file.extension == 'xlsx'" class="fa fa-file-excel-o" aria-hidden="true" style="color: green"></i>
                            <i *ngIf="file.extension == 'ppt' || file.extension == 'pptx'" class="fa fa-file-powerpoint-o" aria-hidden="true" style="color: orange"></i>
                            <i *ngIf="file.extension == 'pdf'" class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i>
                            <i *ngIf="file.extension == 'zip' || file.extension == 'rar'" class="fa fa-file-archive-o" aria-hidden="true" style="color: dimgray"></i>
                            <i *ngIf="file.extension == 'png' || file.extension == 'jpg' || file.extension == 'jpeg'" class="fa fa-file-image-o" aria-hidden="true" style="color: purple"></i>
                            <i *ngIf="file.extension != 'doc'
                           && file.extension != 'docx'
                           && file.extension != 'xls'
                           && file.extension != 'xlsx'
                           && file.extension != 'ppt'
                           && file.extension != 'pptx'
                           && file.extension != 'pdf'
                           && file.extension != 'zip'
                           && file.extension != 'rar'
                           && file.extension != 'png'
                           && file.extension != 'jpg'
                           && file.extension != 'jpeg'"
                               class="fa fa-file-o" aria-hidden="true"></i>
                            {{file.name}}
                        </td>
                        <td>{{file.sizeMb}} Мб</td>
                        <td style="text-align: center">
                            <a title="Скачать" (click)="downloadFile(file.id)" href="javascript:void(0)"><span style="color: green" class="glyphicon glyphicon-save" aria-hidden="true"></span></a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <br />
        <div>

        </div>
        <div>
            <span style="font-size: 15px; font-weight: bold;">Ваш комментарий и вложения:</span><br />
            <textarea [(ngModel)]="checklistTicketToReply.responseComment" class="form-control" placeholder="Ответный комментарий"></textarea>
        </div>
        <br />
        <div>
            <table class="table" *ngIf="checklistTicketToReply.outFiles.length != 0">
                <thead>
                    <tr>
                        <th scope="col" style="width: 80%">Файл</th>
                        <th scope="col" style="width: 10%">Размер</th>
                        <th scope="col" style="width: 10%; text-align: center">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let file of checklistTicketToReply.outFiles">
                        <td>
                            <i *ngIf="file.extension == 'doc' || file.extension == 'docx'" class="fa fa-file-word-o" aria-hidden="true" style="color: blue"></i>
                            <i *ngIf="file.extension == 'xls' || file.extension == 'xlsx'" class="fa fa-file-excel-o" aria-hidden="true" style="color: green"></i>
                            <i *ngIf="file.extension == 'ppt' || file.extension == 'pptx'" class="fa fa-file-powerpoint-o" aria-hidden="true" style="color: orange"></i>
                            <i *ngIf="file.extension == 'pdf'" class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i>
                            <i *ngIf="file.extension == 'zip' || file.extension == 'rar'" class="fa fa-file-archive-o" aria-hidden="true" style="color: dimgray"></i>
                            <i *ngIf="file.extension == 'png' || file.extension == 'jpg' || file.extension == 'jpeg'" class="fa fa-file-image-o" aria-hidden="true" style="color: purple"></i>
                            <i *ngIf="file.extension != 'doc'
                             && file.extension != 'docx'
                             && file.extension != 'xls'
                             && file.extension != 'xlsx'
                             && file.extension != 'ppt'
                             && file.extension != 'pptx'
                             && file.extension != 'pdf'
                             && file.extension != 'zip'
                             && file.extension != 'rar'
                             && file.extension != 'png'
                             && file.extension != 'jpg'
                             && file.extension != 'jpeg'"
                               class="fa fa-file-o" aria-hidden="true"></i>
                            {{file.name}}
                        </td>
                        <td>{{file.sizeMb}} Мб</td>
                        <td style="text-align: center">
                            <a title="Скачать" (click)="downloadFile(file.id)" href="javascript:void(0)"><span style="color: green" class="glyphicon glyphicon-save" aria-hidden="true"></span></a>
                            <a title="Удалить" href="javascript:void(0)" (click)="deleteFileBinding(file, checklistTicketToReply)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div style="margin-top: 5px;">
                <kendo-upload [saveUrl]="fileUploadUrl"
                              [removeUrl]="fileRemoveUrl"
                              (upload)="uploadEventHandler($event)"
                              (remove)="removeEventHandler($event)"
                              (success)="uploaded($event)">
                    <kendo-upload-messages select="Добавить вложение"
                                           uploadSelectedFiles="Загрузить выбранные файлы"
                                           clearSelectedFiles="Удалить выбранные файлы">
                    </kendo-upload-messages>
                </kendo-upload>
            </div>
        </div>


        <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
            <button type="button" (click)="saveReply()" class="btn btn-success">Сохранить</button>
        </div>
    </div>
</ng-template>

<ng-template #edit>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Изменить запись</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="margin-top: -10px" *ngIf="!newTicket.action">
            Наименование:
            <input [disabled]='newTicket.hasChecklist && newTicket.checklist.user.id != userState?.currentUser?.state?.id' type="text" [(ngModel)]="newTicket.name" class="form-control" placeholder="Название записи">
        </div>
        <br>
        <div>
            Дата выполнения:
            <input type="text"
                   placeholder="Щелкните здесь для выбора"
                   class="form-control"
                   [(ngModel)]="newTicket.date"
                   AUTOCOMPLETE="off"
                   [bsConfig]="bsConfig"
                   bsDatepicker>
        </div>
        <div *ngIf="!newTicket.action?.isDeleted && newTicket.action && newTicket.action?.workSchedule.user.id == userState.currentUser.state.id" style="margin-top: 15px">
            <div class="alert alert-info" role="alert">
                <p>При изменении даты выполнения этого задания изменится дата выполнения связанного мероприятия, так как мероприятие &laquo;{{newTicket.action.name}}&raquo; находится в плане	&laquo;{{newTicket.action.workSchedule.name}}&raquo;</p>
            </div>
        </div>
        <div style="margin-top: 15px">
            Время начала:
            <input type="text" [(ngModel)]="newTicket.hours" class="clock form-control" placeholder="ЧЧ"> :
            <input type="text" [(ngModel)]="newTicket.minutes" class="clock form-control" placeholder="ММ">
        </div>
        <br>
        <div>
            Комментарий:
            <textarea style="height: 200px;" [disabled]='newTicket.hasChecklist && newTicket.checklist?.user.id != userState?.currentUser?.state?.id' [(ngModel)]="newTicket.comment" class="form-control" placeholder="Комментарий"></textarea>
        </div>

        <br>
        <div style="color: darkgray; text-align: left; font-size: smaller;" *ngIf="newTicket.hasChecklist && newTicket.created != '0001-01-01T00:00:00'">
            <span *ngIf="newTicket.checklist?.user.id != userState?.currentUser?.state?.id">Задание было создано {{newTicket.created | date : 'dd.MM.yyyy'}} <br /> в чек-листе  &laquo;{{newTicket.checklist.name}}&raquo; пользователя {{newTicket.checklist.user.fullName}}</span>
            <span *ngIf="newTicket.checklist?.user.id == userState?.currentUser?.state?.id">Задание было создано {{newTicket.created | date : 'dd.MM.yyyy'}} <br /> в вашем чек-листе &laquo;{{newTicket.checklist.name}}&raquo;</span>
        </div>

        <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
            <button type="button" (click)="saveTicket()" class="btn btn-success">Сохранить</button>
        </div>

    </div>

</ng-template>

<ng-template #add>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Создать запись</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="margin-top: -10px">
            Наименование:
            <input type="text" [(ngModel)]="newTicket.name" class="form-control" placeholder="Название записи">
        </div>

        <div style="margin-top: 10px">
            Дата:
            <input type="text"
                   placeholder="Щелкните здесь для выбора"
                   class="form-control"
                   [(ngModel)]="newTicket.date"
                   AUTOCOMPLETE="off"
                   [bsConfig]="bsConfig"
                   bsDatepicker>
        </div>

        <div style="margin-top: 10px">
            Время начала:
            <input type="text" [(ngModel)]="newTicket.hours" class="clock form-control" placeholder="ЧЧ"> :
            <input type="text" [(ngModel)]="newTicket.minutes" class="clock form-control" placeholder="ММ">
        </div>
        <br />

        <app-repeat (days)="setDays($event)" (dateTo)="setDateTo($event)" (repeatAction)="setRepeat($event)"></app-repeat>

        <div style="margin-top: 20px">
            Комментарий:
            <textarea [(ngModel)]="newTicket.comment" class="form-control"></textarea>
        </div>

        <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
            <button type="button" (click)="addTicket()" class="btn btn-success">Сохранить</button>
        </div>

    </div>

</ng-template>

<ng-template #deleteAll>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Удалить все записи?</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Было найдно ещё {{similarTickets.length}} записей похожих на	&laquo;{{currentTicket.name}}&raquo;</p>
        <p>Дата последней записи: {{similarTickets[similarTickets.length - 1].date | date : 'dd.MM.yyyy'}}</p>

        <a href="javascript:void(0)" (click)="showAllSimilar = !showAllSimilar"><span *ngIf="!showAllSimilar">Посмотреть</span><span *ngIf="showAllSimilar">Скрыть</span> все похожие записи</a>

        <div *ngIf="showAllSimilar" style="overflow-y: scroll; height:400px;">
            <p *ngFor="let t of similarTickets">
                {{t.date | date : 'dd.MM.yyyy'}} {{t.name}} {{t.comment}}
            </p>
        </div>

        <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="deleteSimilar()" class="btn btn-danger">Удалить все похожие записи</button>
            <button type="button" (click)="deleteOne()" class="btn btn-success">Удалить только выбранную</button>
        </div>
    </div>
</ng-template>

<ng-template #assignedTasksModal>
    <div class="modal-header" style="border-bottom: none !important">
        <h4 class="modal-title pull-left">Задания</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" style="width: 1%"></th>
                    <th scope="col" style="width: 15%">Дата</th>
                    <th scope="col" style="width: 10%">Время</th>
                    <th scope="col" style="width: 65%">Задание</th>
                    <th scope="col" style="width: 9%; text-align: center">Действия</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ticket of userState.assignedTickets.state" [class.expired]="false">
                    <td><span placement="left" theme="light" z-index="10000" [title]="getTooltip(ticket)" style="color: skyblue" class="glyphicon glyphicon-info-sign" aria-hidden="true"></span></td>
                    <td><span *ngIf="ticket.date">{{ticket.date | date : 'dd.MM.yyyy'}}</span></td>
                    <td><span *ngIf="ticket.hours">{{ticket.hours}}:{{ticket.minutes}}<span *ngIf="ticket.minutes < 10">0</span></span></td>
                    <td>
                        {{ticket.name}} <br/>
                        <span class="badge" style="background-color: #83bbe2 !important; font-size: smaller;" *ngIf="ticket.onCheck && ticket.incomingDocument && ticket.incomingDocument.user">{{ticket.incomingDocument.user.fullName}}</span> 
                        <span class="badge" style="background-color: #83bbe2 !important; font-size: smaller;" *ngIf="ticket.onCheck && ticket.outgoingDocument && ticket.outgoingDocument.user">{{ticket.outgoingDocument.user.fullName}}</span> 
                    </td>
                    <td style="text-align: center">
                        <a title="Принять" href="javascript:void(0)" (click)="acceptTicket(ticket)"><span style="color: green" class="glyphicon glyphicon-pushpin" aria-hidden="true"></span></a>
                        <a title="Отклонить" href="javascript:void(0)" (click)="openDeclineDialog(declineModal, ticket)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #declineModal>
    <div class="modal-body text-center">
        <p>Отклонить задание <br /> &laquo;{{ticketToDecline.name}}&raquo; ? </p>
        <button type="button" class="btn btn-success" (click)="modalRef.hide()">Нет</button>
        <button type="button" class="btn btn-danger" (click)="modalRef.hide(); declineTicket()">Да</button>
    </div>
</ng-template>

<ng-template #addWithTime>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Прием задания</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Установка даты или времени для этого задания была оставлена на ваше усмотрение</p>

        <div>
            Дата:
            <input [attr.disabled]="ticketToAccept.blockedData" type="text"
                   placeholder="Щелкните здесь для выбора"
                   class="form-control"
                   [(ngModel)]="ticketToAccept.date"
                   AUTOCOMPLETE="off"
                   [bsConfig]="bsConfig"
                   bsDatepicker>
        </div>
        <br />
        <div>
            Время начала:
            <input [attr.disabled]="ticketToAccept.blockedTime" type="text" [(ngModel)]="ticketToAccept.hours" class="clock form-control" placeholder="ЧЧ"> :
            <input [attr.disabled]="ticketToAccept.blockedTime" type="text" [(ngModel)]="ticketToAccept.minutes" class="clock form-control" placeholder="ММ">
        </div>
        <br />

        <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
            <button type="button" (click)="acceptWithNewDateTimeTicket()" class="btn btn-success">Принять задание</button>
        </div>
    </div>
</ng-template>

<ng-template #fullComment>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Полный комментарий</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <textarea readonly class="full-comment-textarea">{{this.newTicket.comment}}</textarea>
    </div>
</ng-template>

<ng-template #detailsModal>
    <div class="modal-header">
        <h4 style="width: 95%; font-weight: bold;" class="modal-title pull-left">{{checklistTicketToSeeDetails.name}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <p>Чек-лист: &laquo;{{checklistTicketToSeeDetails.checklist.name}}&raquo; <br /> Автор: {{checklistTicketToSeeDetails.checklist.user.fullName}}</p>
            <br />
            <span style="font-size: 15px; font-weight: bold;">Комментарий и вложения автора:</span><br />
            <span>
                <textarea readonly class="full-comment-textarea">{{checklistTicketToSeeDetails.comment}}</textarea> 
            </span>
            <div *ngIf="checklistTicketToSeeDetails.inFiles.length == 0" style="text-align: center; margin-top: 25px; font-style: italic;"><span>Автор задания пока не оставил ни одного вложения</span></div>
            <table class="table" *ngIf="checklistTicketToSeeDetails.inFiles.length != 0">
                <thead>
                    <tr>
                        <th scope="col" style="width: 80%">Файл</th>
                        <th scope="col" style="width: 10%">Размер</th>
                        <th scope="col" style="width: 10%; text-align: center">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let file of checklistTicketToSeeDetails.inFiles">
                        <td>
                            <i *ngIf="file.extension == 'doc' || file.extension == 'docx'" class="fa fa-file-word-o" aria-hidden="true" style="color: blue"></i>
                            <i *ngIf="file.extension == 'xls' || file.extension == 'xlsx'" class="fa fa-file-excel-o" aria-hidden="true" style="color: green"></i>
                            <i *ngIf="file.extension == 'ppt' || file.extension == 'pptx'" class="fa fa-file-powerpoint-o" aria-hidden="true" style="color: orange"></i>
                            <i *ngIf="file.extension == 'pdf'" class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i>
                            <i *ngIf="file.extension == 'zip' || file.extension == 'rar'" class="fa fa-file-archive-o" aria-hidden="true" style="color: dimgray"></i>
                            <i *ngIf="file.extension == 'png' || file.extension == 'jpg' || file.extension == 'jpeg'" class="fa fa-file-image-o" aria-hidden="true" style="color: purple"></i>
                            <i *ngIf="file.extension != 'doc'
                           && file.extension != 'docx'
                           && file.extension != 'xls'
                           && file.extension != 'xlsx'
                           && file.extension != 'ppt'
                           && file.extension != 'pptx'
                           && file.extension != 'pdf'
                           && file.extension != 'zip'
                           && file.extension != 'rar'
                           && file.extension != 'png'
                           && file.extension != 'jpg'
                           && file.extension != 'jpeg'"
                               class="fa fa-file-o" aria-hidden="true"></i>
                            {{file.name}}
                        </td>
                        <td>{{file.sizeMb}} Мб</td>
                        <td style="text-align: center">
                            <a title="Скачать" (click)="downloadFile(file.id)" href="javascript:void(0)"><span style="color: green" class="glyphicon glyphicon-save" aria-hidden="true"></span></a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <br />
       
        <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="closeModal()" class="btn btn-default">Вернуться к списку заданий</button>
            <button type="button" (click)="declineTicket(this.checklistTicketToSeeDetails)" class="btn btn-danger">Отклонить</button>
            <button type="button" (click)="acceptTicket(this.checklistTicketToSeeDetails, true)" class="btn btn-success">Принять</button>
        </div>
    </div>
</ng-template>

<p-toast></p-toast> 
