export enum HealthGroup{
    first,
    second,
    third
}

export const healthGroups: any[] = [
  { id: 0, name: 'I' },
  { id: 1, name: 'II' },
  { id: 2, name: 'III' }
];