<div class="pane">
    <button class="btn btn-primary" (click)="getDocument()">Сформировать документ <span class="glyphicon glyphicon-file" aria-hidden="true"></span></button>
</div>
<span *ngIf="!rows || (rows[0] && rows[0].cells.length==0)" style="text-align: center; margin-top: 150px; font-style: italic;">Выберите учебный год, объединение, группу, создайте КТП для отображения журнала</span>
<div *ngIf="rows && rows[0] && rows[0].cells.length > 0">
  <div class="rgt-container">
    <table class="rg-table" #renderedTable>
      <tr class="head"> 
        <td rowspan="2">Ученики</td> 
        <td *ngFor="let month of getMonths()" [attr.colspan]="month.days">{{month.monthName}}</td>
      </tr>
      <tr class="head"> 
        <td *ngFor="let day of getDays()">{{day}}</td>
      </tr>
       
      <tr *ngFor="let row of rows" title="{{row.student.fullName}}"> 
        <td>
          {{row.student.fullName}}
        </td>
        <td *ngFor="let cell of row.cells" 
          (click)="onCellClick(cell, row.student.id); menu.toggle($event)"
          style="cursor: pointer;">
           <!-- <input 
              type="text" 
              class="cell-input"
              id="row{{rows.indexOf(row)}}cell{{row.cells.indexOf(cell)}}"
              [(ngModel)]="cell.content"
              (ngModelChange)="updateCell(cell, row.student.id)"> -->
              <span>{{cell.content}}</span>
        </td>
      </tr>
    </table>
  </div>
  
  <div class="legend print-block">	
    <div>		
      <p>
        <span class="legend-label">УП</span>
        <span class="legend-description"> — Пропуск по уважительной причине</span>
      </p>		
      <p>
        <span class="legend-label">Б</span>
        <span class="legend-description"> — Пропуск по болезни</span>
      </p>		
      <p>
        <span class="legend-label">НП</span>
        <span class="legend-description"> — Пропуск по неуважительной причине</span>
      </p>	
    </div>	
    <div>		
      <p>
        <span class="legend-label">ОТ</span>
        <span class="legend-description"> — Отсутствовал</span>
      </p>		
      <p>
        <span class="legend-label">ОП</span>
        <span class="legend-description"> — Опоздал</span>
      </p>	
    </div>	
    <div>		
      <p>
        <span class="legend-label">ОСВ</span>
        <span class="legend-description"> — Освобожден</span>
      </p>	
    </div>
  </div>
</div>

<p-tieredMenu #menu [model]="menuItems" [popup]="true" appendTo="body"></p-tieredMenu>