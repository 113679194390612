<div>
  <button style="float: right;" (click)="showAll = !showAll" type="button" class="btn btn-light"><span *ngIf="!showAll">Показать</span><span *ngIf="showAll">Скрыть</span></button>
  <div>
    <h3>План: "{{schedule.name}}"</h3>
    <h4>Автор: {{schedule.user.fullName}}</h4>
  </div>
</div>
<div *ngIf="showAll">
  <table class="table">
    <thead>
      <tr>
        <th scope="col"><input type="checkbox" [(ngModel)]="selectedAll" (click)="selection()" /></th>
        <th scope="col">Дата</th>
        <th scope="col">Мероприятие</th>
        <th scope="col">Ответственные</th>
        <th scope="col">Форма подтверждения</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let action of schedule.actions">
        <td><input type="checkbox" [(ngModel)]="action.selected" (ngModelChange)="select()" /></td>
        <td>{{action.date | date : 'dd.MM.yyyy'}}</td>
        <td>{{action.name}}</td>
        <td>
          <span *ngFor="let user of action.responsibles" class="badge">
            {{user.lastName}} {{user.firstName[0]}}. {{user.surName[0]}}.
          </span> &nbsp;
        </td>
        <td>{{action.confirmationForm.name}}</td>
      </tr>
    </tbody>
  </table>

  <div style="text-align: center; margin-bottom: 50px">
    <button type="button" (click)="cancelEmit()" class="btn btn-danger">Отклонить отмеченные мероприятия</button>
    <button type="button" (click)="executeEmit()" class="btn btn-success"><span *ngIf="accept">Утвердить</span> <span *ngIf="!accept">Согласовать</span> отмеченные мероприятия</button>
  </div>
</div>

