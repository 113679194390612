<div class='container-fluid'>
    <div class='row'>
      <div class='col-sm-3'>
        <div class='main-nav'>
          <div class='navbar navbar-inverse'>
            <div class='navbar-header'>
              <button type='button' class='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse' [attr.aria-expanded]='isExpanded' (click)='toggle()'>
                <span class='sr-only'>Toggle navigation</span>
                <span class='icon-bar'></span>
                <span class='icon-bar'></span>
                <span class='icon-bar'></span>
              </button>
            </div>
            <div class='clearfix'></div>
            <div class='navbar-collapse collapse' [ngClass]='{ "in": isExpanded }'>
              <div style="font-size: 18px; margin: 15px;">
                <span>
                  <span class='glyphicon glyphicon-user'></span>
                  <span *ngIf="!userState.currentUser.state">Вход не выполнен</span>
                  <span *ngIf="userState.currentUser.state">
                    {{userState.currentUser?.state?.firstName[0]}}.
                    {{userState.currentUser?.state?.surName[0]}}.
                    {{userState.currentUser?.state?.lastName}}
                  </span>
                </span>
                <a href="javascript:void(0)" (click)="logout()" *ngIf="userState.currentUser.state" style="font-size: 14px;">выйти из системы</a>
              </div>
        
              <h3 style="margin: 20px;">
                Документация
              </h3>
        
              <ul class='nav navbar-nav'>
                  <li [routerLinkActive]='["link-active"]'>
                      <a routerLink='incoming' (click)='collapse()'>
                          <span class='glyphicon glyphicon-book' style="color: yellowgreen"></span>
                          <span>Входящая</span>
                      </a>
                  </li>
        
                  <li [routerLinkActive]='["link-active"]'>
                      <a routerLink='outgoing' (click)='collapse()'>
                          <span class='glyphicon glyphicon-book' style="color: cornflowerblue;"></span>
                          <span>Исходящая</span>
                      </a> 
                  </li>
                
                  <li>
                      <a routerLink='/' (click)='collapse()'>
                          <span class='glyphicon glyphicon-log-out'></span> К другим приложениям
                      </a>
                  </li>
              </ul>
            </div>
        
          </div>
        </div>
      </div>
      <div class='col-sm-9 body-content router-content'>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  