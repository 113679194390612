<h1>Сведения об учащихся</h1>
<div class="tabs">
  <tabset>
    <tab heading="Ученики">
      <app-students></app-students>
    </tab>
    <tab heading="Классы">
      <app-classes></app-classes>
    </tab>
  </tabset>
</div>