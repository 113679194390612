<!-- -->
<h2>Социальный паспорт семьи</h2>
<div class="panelling">
    <div class="panelling_select">
        <div class="panelling_select-years">
            <ng-select [items]="allAcademicYears"
                       style="width: 300px"
                       placeholder="Выберите учебный год"
                       bindLabel="name"
                       [(ngModel)]="selectedAcademicYear"
                       (ngModelChange)="loadClasses()">
            </ng-select>
        </div>
        <div class="panelling_select-classes">
            <ng-select [items]="classes"
                       style="width: 300px;"
                       placeholder="Выберите класс"
                       bindLabel="name"
                       [(ngModel)]="selectedClass"
                       (ngModelChange)="loadFamilies()">
            </ng-select>
        </div>
    </div>
    <div class="panelling_button">
        <button pButton
                pRipple
                icon="pi pi-check-square"
                (click)="showModalDialogColumns()"
                type="button"
                label="Выбрать колонки для отображения"
                style="margin-top: 8px; float: right;">
        </button>
        <button pButton
                pRipple
                icon="pi pi-plus"
                (click)="showAddModal()"
                type="button"
                class="p-button-success"
                label="Добавить запись"
                style="margin: 8px 4px; float: right;">
        </button>
    </div>
</div>

<table class="table">
    <thead>
      <tr>
        <th *ngFor = "let column of getVisibleColumns()" scope="col">{{column.name}}</th>
        <th scope ="col">Действия</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let family of families">
          <td *ngFor="let column of getVisibleColumns()"><span style="white-space: pre-wrap">{{getPropertyValue(family, column.fieldInfo)}}</span></td>
          <td>
            <div class="action-icons">
              <a title="Редактировать" href="javascript:void(0)" class="pi pi-pencil" aria-hidden="true" (click)="showModalDialogEdit(family)"></a>
              <a title="Удалить" href="javascript:void(0)" (click)="delete(family.id)" class="pi pi-times" aria-hidden="true" style="color: red;"></a>
            </div>
          </td>
      </tr>
    </tbody>
</table>


<!-- Modal windows -->
 
<!-- Selection visible columns -->
<p-dialog header="Выбор колонки для отображения" 
          [(visible)]="displayModalColumns" 
          [modal]="true" 
          [style]="{width: '30vw'}" 
          [baseZIndex]="10000"
          [draggable]="false">
    <div>
        <div *ngFor="let column of modalColumns" style="margin-bottom: 5px;">
            <p-checkbox [(ngModel)]="column.visibility" [binary]="true"></p-checkbox>
            <label>{{ column.name }}</label>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button (click)="hideModal()" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<p-dialog header="Изменить запись"
          [(visible)]="editModalVisibility"
          [modal]="true"
          [style]="{width: '50vw'}"
          [baseZIndex]="100"
          [draggable]="false">
    <div class="family-card">
        <div>
            ФИО ребенка:
            <input type="text" [ngModel]="newFamily.student.fullName" disabled class="form-control">
        </div>
        <div>
            Номер свидетельства о рождении:
            <input type="text" [(ngModel)]="newFamily.birthCertificate" class="form-control" placeholder="Номер свидетельства о рождении">
        </div>
        <div>
            Паспортные данные:
            <input type="text" [(ngModel)]="newFamily.passportNumber" class="form-control" placeholder="Серия и номер паспорта, кем и когда выдан">
        </div>
        <div>
            Адрес регистрации:
            <input type="text" [(ngModel)]="newFamily.registrAddres" class="form-control" placeholder="Адрес регистрации">
        </div>
        <div>
            Адрес проживания:
            <input type="text" [(ngModel)]="newFamily.residAddres" class="form-control" placeholder="Адрес проживания">
        </div>
        <div>
            ФИО матери:
            <input type="text" [(ngModel)]="newFamily.fullNameMather" class="form-control" placeholder="ФИО матери">
        </div>
        <div>
            Телефон матери:
            <input type="text" [(ngModel)]="newFamily.phoneMother" class="form-control" placeholder="Телефон матери">
        </div>
        <div>
            Место работы матери:
            <input type="text" [(ngModel)]="newFamily.workMother" class="form-control" placeholder="Место работы матери">
        </div>
        <div>
            ФИО отца:
            <input type="text" [(ngModel)]="newFamily.fullNameFather" class="form-control" placeholder="ФИО отца">
        </div>
        <div>
            Телефон отца:
            <input type="text" [(ngModel)]="newFamily.phoneFather" class="form-control" placeholder="Телефон отца">
        </div>
        <div>
            Место работы отца:
            <input type="text" [(ngModel)]="newFamily.workFather" class="form-control" placeholder="Место работы отца">
        </div>
        <div>
            Категория семьи по составу:
            <ng-select [items]="compositions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.familycomposition">
            </ng-select>
        </div>
        <div>
            Подкатегория семьи по составу:
            <ng-select [items]="clarifycompositions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.clarifyFamilycomposition">
            </ng-select>
        </div>

        <div>
            Категория семьи по количеству детей:
            <ng-select [items]="numbersChildren"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.familyNumberChildren">
            </ng-select>
        </div>

        <div>
            Категория семьи по качеству жизни:
            <ng-select [items]="qualityLifes"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.familyQualityLife">
            </ng-select>
        </div>

        <div>
            Группа здоровья:
            <ng-select [items]="healthGroups"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.healthGroup">
            </ng-select>
        </div>

        <div>
            Физическая группа:
            <ng-select [items]="physicalGroups"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.physicalGroup">
            </ng-select>
        </div>

        <div>
            Учет:
            <ng-select [items]="registrations"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.registration">
            </ng-select>
        </div>
        <div style="text-align:center; margin-top: 15px;">
            <!--<button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>-->
            <button type="button" (click)="editFamily()" class="btn btn-success">Изменить запись</button>
        </div>
    </div>
</p-dialog>

<p-dialog header="Добавить запись"
          [(visible)]="addModalVisibility"
          [modal]="true"
          [style]="{width: '50vw'}"
          [baseZIndex]="100"
          [draggable]="false">
    <div class="family-card">
        <div>
            ФИО ребенка:
            <ng-select [items]="students"
                       bindLabel="fullName"
                       bindValue="id"
                       placeholder="Выберите ребенка из списка"
                       [(ngModel)]="newFamily.student.id">
            </ng-select>
        </div>
        <div>
            Номер свидетельства о рождении:
            <input type="text" [(ngModel)]="newFamily.birthCertificate" class="form-control" placeholder="Номер свидетельства о рождении">
        </div>
        <div>
            Паспортные данные:
            <input type="text" [(ngModel)]="newFamily.passportNumber" class="form-control" placeholder="Серия и номер паспорта, кем и когда выдан">
        </div>
        <div>
            Адрес регистрации:
            <input type="text" [(ngModel)]="newFamily.registrAddres" class="form-control" placeholder="Адрес регистрации">
        </div>
        <div>
            Адрес проживания:
            <input type="text" [(ngModel)]="newFamily.residAddres" class="form-control" placeholder="Адрес проживания">
        </div>
        <div>
            ФИО матери:
            <input type="text" [(ngModel)]="newFamily.fullNameMather" class="form-control" placeholder="ФИО матери">
        </div>
        <div>
            Телефон матери:
            <input type="text" [(ngModel)]="newFamily.phoneMother" class="form-control" placeholder="Телефон матери">
        </div>
        <div>
            Место работы матери:
            <input type="text" [(ngModel)]="newFamily.workMother" class="form-control" placeholder="Место работы матери">
        </div>
        <div>
            ФИО отца:
            <input type="text" [(ngModel)]="newFamily.fullNameFather" class="form-control" placeholder="ФИО отца">
        </div>
        <div>
            Телефон отца:
            <input type="text" [(ngModel)]="newFamily.phoneFather" class="form-control" placeholder="Телефон отца">
        </div>
        <div>
            Место работы отца:
            <input type="text" [(ngModel)]="newFamily.workFather" class="form-control" placeholder="Место работы отца">
        </div>
        <div>
            Категория семьи по составу:
            <ng-select [items]="compositions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.familycomposition">
            </ng-select>
        </div>
        <div>
            Подкатегория семьи по составу:
            <ng-select [items]="clarifycompositions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.clarifyFamilycomposition">
            </ng-select>
        </div>

        <div>
            Категория семьи по количеству детей:
            <ng-select [items]="numbersChildren"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.familyNumberChildren">
            </ng-select>
        </div>

        <div>
            Категория семьи по качеству жизни:
            <ng-select [items]="qualityLifes"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.familyQualityLife">
            </ng-select>
        </div>

        <div>
            Группа здоровья:
            <ng-select [items]="healthGroups"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.healthGroup">
            </ng-select>
        </div>

        <div>
            Физическая группа:
            <ng-select [items]="physicalGroups"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.physicalGroup">
            </ng-select>
        </div>

        <div>
            Учет:
            <ng-select [items]="registrations"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="newFamily.registration">
            </ng-select>
        </div>
        <div style="text-align:center; margin-top: 15px;">
            <!--<button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>-->
            <button type="button" (click)="addFamily()" class="btn btn-success">Создать запись</button>
        </div>
    </div>
</p-dialog>

<!-- <span style="color: purple" class="glyphicon glyphicon-pencil" aria-hidden="true"></span> 
<span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span>-->

<p-toast></p-toast> 