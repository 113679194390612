<h1>Планы для согласования</h1>
<div *ngFor="let schedule of schedules">
  <app-confirm-accept-schedule
                               [schedule]="schedule"
                               [accept]="false"
                               (execute)="confirm($event)"
                               (cancel)="cancel($event)">
  </app-confirm-accept-schedule>
</div>

<div style="text-align: center; margin-top: 70px; font-style: italic;" *ngIf="!schedules || schedules?.length == 0"><span>Пока никто не отправлял мероприятия на согласование</span></div>


<p-toast></p-toast> 
