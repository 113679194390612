<div>
  <h1>Администрирование</h1>   
  <div class="tabs">
    <tabset>
      <tab heading="ДО">
        <app-register-table-settings 
          [allAcademicYears]="allAcademicYears" 
          [associationType]="0"
          [students]="studentService.students">
        </app-register-table-settings>
      </tab>
      <tab heading="ВД">
        <app-register-table-settings 
          [allAcademicYears]="allAcademicYears" 
          [associationType]="1"
          [students]="studentService.students">
        </app-register-table-settings>
      </tab>
      <tab heading="ГПД">
        <app-register-table-settings 
          [allAcademicYears]="allAcademicYears" 
          [associationType]="2"
          [students]="studentService.students">
        </app-register-table-settings>
      </tab>
    </tabset>
  </div>
</div>
