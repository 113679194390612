<p><input type="checkbox" [(ngModel)]="repeat" (ngModelChange)="repeatChanged()"> Повторять задание по дням </p>
<div *ngIf="repeat">
  <form>
    <div class="days">
      <div>
        <p><input name="mon" type="checkbox" [(ngModel)]="mon" (ngModelChange)="dayChanged()"> ПН</p>
      </div>
      <div>
        <p><input name="tue" type="checkbox" [(ngModel)]="tue" (ngModelChange)="dayChanged()"> ВТ</p>
      </div>                     
      <div>                       
        <p><input name="wed" type="checkbox" [(ngModel)]="wed" (ngModelChange)="dayChanged()"> СР</p>
      </div>                  
      <div>                  
        <p><input name="thr" type="checkbox" [(ngModel)]="thr" (ngModelChange)="dayChanged()"> ЧТ</p>
      </div>              
      <div>                   
        <p><input name="fri" type="checkbox" [(ngModel)]="fri" (ngModelChange)="dayChanged()"> ПТ</p>
      </div>                 
      <div>                     
        <p><input name="sat" type="checkbox" [(ngModel)]="sat" (ngModelChange)="dayChanged()"> СБ</p>
      </div>                
      <div>                  
        <p><input name="sun" type="checkbox" [(ngModel)]="sun" (ngModelChange)="dayChanged()"> ВС</p>
      </div>
    </div>
  </form>

  <div>
    до этой даты (включительно):
    <input type="text"
           placeholder="Щелкните здесь для выбора"
           class="form-control"
           [(ngModel)]="selectedDateTo"
           (ngModelChange)="dateChanged()"
           AUTOCOMPLETE="off"
           [bsConfig]="bsConfig"
           bsDatepicker>
  </div>
</div>
