<div class='container-fluid'>
  <div class='row'>
    <div class='col-sm-3'>
      <div class='main-nav'>
        <div class='navbar navbar-inverse'>
          <div class='navbar-header'>
            <button type='button' class='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse' [attr.aria-expanded]='isExpanded' (click)='toggle()'>
              <span class='sr-only'>Toggle navigation</span>
              <span class='icon-bar'></span>
              <span class='icon-bar'></span>
              <span class='icon-bar'></span>
            </button>
          </div>
          <div class='clearfix'></div>
          <div class='navbar-collapse collapse' [ngClass]='{ "in": isExpanded }'>
            <div style="font-size: 18px; margin: 15px;">
              <span>
                <span class='glyphicon glyphicon-user'></span>
                <span *ngIf="!userState.currentUser.state">Вход не выполнен</span>
                <span *ngIf="userState.currentUser.state">
                  {{userState.currentUser?.state?.firstName[0]}}.
                  {{userState.currentUser?.state?.surName[0]}}.
                  {{userState.currentUser?.state?.lastName}}
                </span>
              </span>
              <a href="javascript:void(0)" (click)="logout()" *ngIf="userState.currentUser.state" style="font-size: 14px;">выйти из системы</a>
            </div>
      
            <h3 style="margin: 20px;">
              Мониторинг
            </h3>

            <ul class='nav navbar-nav'>
              <li [routerLinkActive]='["link-active"]'>
                <a routerLink='lists' (click)='collapse()'>
                    <span class='glyphicon glyphicon-align-justify' style="color: #7756a2;"></span>
                    <span>Сведения об учащихся</span>
                </a> 
              </li>

                <li [routerLinkActive]='["link-active"]'>
                    <a routerLink='family' (click)='collapse()'>
                        <span class='glyphicon glyphicon-folder-close' style="color: yellowgreen"></span>
                        <span>Социальный паспорт семьи</span>
                    </a>
                </li>
      
                <!--<li [routerLinkActive]='["link-active"]'>
                    <a (click)='collapse()'>
                        <span class='glyphicon glyphicon-folder-close' style="color: cornflowerblue;"></span>
                        <span>Карта занятости учащегося</span>
                    </a> 
                </li>

                <li [routerLinkActive]='["link-active"]' >
                  <a (click)='collapse()'>
                      <span class='glyphicon glyphicon-folder-close' style="color: #a09e5f;"></span>
                      <span>Награды работников</span>
                  </a> 
                </li>

                <li [routerLinkActive]='["link-active"]'>
                  <a (click)='collapse()'>
                      <span class='glyphicon glyphicon-folder-close' style="color: #905fa0;"></span>
                      <span>Кадры</span>
                  </a> 
                </li>

                <li [routerLinkActive]='["link-active"]'>
                  <a (click)='collapse()'>
                      <span class='glyphicon glyphicon-folder-close' style="color: #5fa070;"></span>
                      <span>Одаренные дети</span>
                  </a> 
                </li>-->

                <li [routerLinkActive]='["link-active"]'>
                  <a routerLink='contracts' (click)='collapse()'>
                      <span class='glyphicon glyphicon-folder-close' style="color: #5f67a0;"></span>
                      <span>Договоры школы</span>
                  </a> 
                </li>               
      
                <li [routerLinkActive]='["link-active"]'>
                    <a (click)='collapse()'>
                        <span class='glyphicon glyphicon-asterisk' style="color: brown;"></span> Администрирование
                    </a>
                </li>
              
                <li>
                    <a routerLink='/' (click)='collapse()'>
                        <span class='glyphicon glyphicon-log-out'></span> К другим приложениям
                    </a>
                </li>
            </ul>
          </div>
      
        </div>
      </div>
    </div>
    <div class='col-sm-9 body-content router-content'>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
