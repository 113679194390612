<button type="button" (click)="openModal(add)" class="btn btn-success top-item" style="float: right; margin: 10px 0;"><span class="glyphicon glyphicon-plus"></span> Зарегистрировать нового ученика</button>&nbsp;

<table class="table" *ngIf="studentService.students && studentService.students.length > 0">
  <thead>
    <tr>
        <th scope="col">Номер дела</th>
        <th scope="col">ФИО</th>
        <th scope="col">Дата рождения</th>
        <th scope="col">Действия</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let student of studentService.students">
      <td>{{student.number}}</td>
      <td>{{student.lastName}} {{student.firstName}} {{student.surName}}</td>
      <td>{{student.birthday | date : 'dd.MM.yyyy'}}</td>
      <td>
        <a title="Архивировать" href="javascript:void(0)"><span style="color: rgb(212, 173, 0)" class="glyphicon glyphicon-compressed action-icons" aria-hidden="true"></span></a>
        <a title="Редактировать" href="javascript:void(0)" (click)="openEditModal(edit, student)"><span style="color: steelblue" class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
        <a title="Удалить" href="javascript:void(0)" (click)="deleteStudent(student.id, deleteModal)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
      </td>
    </tr>
  </tbody>
</table>


<ng-template #add>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Зарегистрировать нового ученика</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div style="margin-top: -10px" class="field-box">
        <div>  
          Номер дела:
          <input type="text" [(ngModel)]="studentToCreate.number" class="form-control" placeholder="Номер">
        </div>
        <div>
          Фамилия:
          <input type="text" [(ngModel)]="studentToCreate.lastName" class="form-control" placeholder="Иванов">
        </div>
        <div>
          Имя:
          <input type="text" [(ngModel)]="studentToCreate.firstName" class="form-control" placeholder="Иван">
        </div>
        <div>
          Отчество:
          <input type="text" [(ngModel)]="studentToCreate.surName" class="form-control" placeholder="Иванович">
        </div>
        <div>
            Дата рождения:
            <input type="text"
                   placeholder="Щелкните здесь для выбора даты"
                   class="form-control"
                   [(ngModel)]="studentToCreate.birthday"
                   AUTOCOMPLETE="off"
                   [bsConfig]="bsConfig"
                   bsDatepicker>
        </div>
      </div>

      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
          <button type="button" (click)="createStudent()" class="btn btn-success">Зарегистрировать</button>
      </div>
  </div>

</ng-template>


<ng-template #edit>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Изменить информацию об ученике</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div style="margin-top: -10px" class="field-box">
        <div>  
          Номер дела:
          <input type="text" [(ngModel)]="studentToCreate.number" class="form-control" placeholder="Номер">
        </div>
        <div>
          Фамилия:
          <input type="text" [(ngModel)]="studentToCreate.lastName" class="form-control" placeholder="Иванов">
        </div>
        <div>
          Имя:
          <input type="text" [(ngModel)]="studentToCreate.firstName" class="form-control" placeholder="Иван">
        </div>
        <div>
          Отчество:
          <input type="text" [(ngModel)]="studentToCreate.surName" class="form-control" placeholder="Иванович">
        </div>
        <div>
            Дата рождения:
            <input type="text"
                   placeholder="Щелкните здесь для выбора даты"
                   class="form-control"
                   [(ngModel)]="studentToCreate.birthday"
                   AUTOCOMPLETE="off"
                   [bsConfig]="bsConfig"
                   bsDatepicker>
        </div>
      </div>

      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
          <button type="button" (click)="updateStudent()" class="btn btn-success">Сохранить</button>
      </div>
  </div>

</ng-template>

<ng-template #deleteModal>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Удалить ученика из системы полностью?</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div style="margin-top: -10px" class="field-box">
        <div>  
          Ученик будет исключен из списков классов за все учебные годы, а также из всех журналов. 
          Если вы хотите удалить ученика без исключения его из списков классов за существующие учебные годы и журналов - выполните архивацию
        </div>

      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-success">Отмена</button>
          <button type="button" (click)="delete(studentIdTodelete)" class="btn btn-danger">Удалить</button>
      </div>
  </div>
  </div>
</ng-template>