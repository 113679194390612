<div class='main-nav'>
  <div class='navbar navbar-inverse'>
    <div class='navbar-header'>
      <button type='button' class='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse' [attr.aria-expanded]='isExpanded' (click)='toggle()'>
        <span class='sr-only'>Toggle navigation</span>
        <span class='icon-bar'></span>
        <span class='icon-bar'></span>
        <span class='icon-bar'></span>
      </button>
    </div>
    <div class='clearfix'></div>
    <div class='navbar-collapse collapse' [ngClass]='{ "in": isExpanded }'>
      <div style="font-size: 18px; margin: 15px;">
        <span>
          <span class='glyphicon glyphicon-user'></span>
          <span *ngIf="!userState.currentUser.state">Вход не выполнен</span>
          <span *ngIf="userState.currentUser.state">
            {{userState.currentUser?.state?.firstName[0]}}.
            {{userState.currentUser?.state?.surName[0]}}.
            {{userState.currentUser?.state?.lastName}}
          </span>
        </span>
        <a href="javascript:void(0)" (click)="logout()" *ngIf="userState.currentUser.state" style="font-size: 14px;">выйти из системы</a>
      </div>

      <h3 style="margin: 20px;">
        Планирование
      </h3>

      <ul class='nav navbar-nav'>
          <!--<li [routerLinkActive]='["link-active"]'>
      <a [routerLink]='["/stat"]' (click)='collapse()'>
        <span class='glyphicon glyphicon-stats'></span> Статистика
      </a>
    </li>-->
          <li [routerLinkActive]='["link-active"]'>
              <a routerLink='my-schedule' (click)='collapse()'>
                  <span class='glyphicon glyphicon-calendar' style="color: darkslateblue;"></span>
                  <span>Планы</span>
              </a>
          </li>

          <li [routerLinkActive]='["link-active"]'>
              <a routerLink='protocols' (click)='collapse()'>
                  <span class='glyphicon glyphicon glyphicon-inbox' style="color: forestgreen;"></span>
                  <span>Протоколы мероприятий</span>
              </a> 
          </li>

          <li *ngIf="userState.currentUser?.state?.canConfirm" [routerLinkActive]='["link-active"]'>
              <a routerLink='confirm' (click)='collapse()'>
                  <span class='glyphicon glyphicon-ok' style="color: cornflowerblue;"></span> Планы для согласования <span *ngIf="userState.schedulesToConfirm.state != 0" class="badge badge-info">{{userState.schedulesToConfirm.state}}</span>
              </a>
          </li>
          <li *ngIf="userState.currentUser?.state?.canAccept" [routerLinkActive]='["link-active"]'>
              <a routerLink='accept' (click)='collapse()'>
                  <span class='glyphicon glyphicon-ok' style="color: coral;"></span> Планы для утверждения <span *ngIf="userState.schedulesToAccept.state != 0" class="badge badge-info">{{userState.schedulesToAccept.state}}</span>
              </a>
          </li>

          <li [routerLinkActive]='["link-active"]'>
              <a routerLink='general-schedule' (click)='collapse()'>
                  <span class='glyphicon glyphicon-th' style="color: purple;"></span> Сводный план
              </a>
          </li>

          <li [routerLinkActive]='["link-active"]' *ngIf="userState.currentUser?.state?.canUseChecklists">
              <a routerLink='checklists' (click)='collapse()'>
                  <span class='	glyphicon glyphicon-check' style="color: tomato;"></span>
                  <span>Чек-листы</span> <span *ngIf="userState.unseenChecklistTickets.state != 0" class="badge badge-info">{{userState.unseenChecklistTickets.state}}</span>
              </a>
          </li>

          <li [routerLinkActive]='["link-active"]'>
              <a routerLink='timeline' (click)='collapse()'>
                  <span class='glyphicon glyphicon-time' style="color: teal;"></span>
                  <span>Тайм-лист</span>
                  <span *ngIf="userState.assignedTicketCount.state != 0" class="badge badge-info">{{userState.assignedTicketCount.state}}</span>
              </a>
          </li>




          <!-- <li *ngIf="checkRole(userState.currentUser?.state, 'Директор')" [routerLinkActive]='["link-active"]'>
      <a routerLink='settings' (click)='collapse()'>
        <span class='glyphicon glyphicon-cog'></span> Настройки
      </a>
    </li> -->
          <li>
              <a routerLink='/' (click)='collapse()'>
                  <span class='glyphicon glyphicon-log-out'></span> К другим приложениям
              </a>
          </li>
      </ul>
    </div>

    <a 
      href="javascript:void(0)" 
      style="position: absolute; bottom: 5px;"
      (click)="report = undefined; openModal(reportModal)">
      Сообщить об ошибке
    </a>

  </div>
</div>


<ng-template #reportModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Сообщить об ошибке</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Большое спасибо за проявленный интерес к качеству программного продукта. <br>
    Опишите, пожалуйста, Вашу проблему максимально полно. <br/> Это поможет исправить ее как можно быстрее.
    <br/> <br/>

    <textarea [(ngModel)]="report" class="form-control" rows="15"></textarea>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="modalRef.hide()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="sendReport()" class="btn btn-success">Отправить</button>
    </div>
  </div>
</ng-template>
