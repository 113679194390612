<h1>Планы мероприятий</h1>

<div class="panel panel-default">
  <div class="panel-body">
<button type="button" (click)="openModal(add)" class="btn btn-success"><span class='glyphicon glyphicon-plus'></span> Создать план</button>
  </div>
</div>


<tabset>
    <tab heading="Мои планы">
        <div *ngFor="let academicYear of academicYearsToShow">
            <h3>{{academicYear.name}} учебный год</h3>
            <div class="list-group">
                <a *ngFor="let schedule of academicYear.workSchedules"
                   href="javascript:void(0)"
                   [routerLink]="['//scheduler/schedule-details', schedule.id]"
                   class="list-group-item list-group-item-action c{{schedule.activity.color}}">
                    {{schedule.name}} ({{schedule.activity.name}})
                </a>
            </div>
        </div>

        <div *ngIf="!academicYearsToShow" style="text-align: center; margin-top: 50px; font-style: italic;"><span class="loading">Загрузка</span></div>
        <div *ngIf="academicYearsToShow && academicYearsToShow.length == 0" style="text-align: center; margin-top: 50px; font-style: italic;"><span>У вас пока нет ни одного плана</span></div>
    </tab>
    <tab heading="Планы других пользователей" *ngIf="userState.currentUser?.state?.canSeeAllSchedules">
        <div *ngFor="let group of otherSchedules">
            <h4>{{group.name}}</h4>
                <div *ngFor="let key of getKeys(group.schedules)" style="margin-left: 25px">
                    {{key}}
                    <div *ngFor="let schedule of group.schedules[key]" style="margin-left: 25px">
                        <a class="c{{schedule.activity.color}}" href="javascript:void(0)" [routerLink]="['//scheduler/schedule-details', schedule.id]">{{schedule.name}}</a>
                    </div>
                </div>
        </div>
    </tab>
</tabset>



<ng-template #add>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Создать план</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Наименование:
    <input class="form-control" [(ngModel)]="name" placeholder="Наименование">
    <br/>

    Направление деятельности:
    <ng-select [items]="allActivities"
               bindLabel="name"
               bindValue="id"
               [(ngModel)]="selectedActivityId"
               [clearable]="false">
    </ng-select>

    <br />

    Учебный год:
    <ng-select [items]="allAcademicYears"
               bindLabel="name"
               bindValue="id"
               [(ngModel)]="selectedAcademicYearId"
               [clearable]="false">
    </ng-select>
    <br>


    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="createSchedule()" class="btn btn-success">Создать</button>
    </div>

  </div>
</ng-template>
<p-toast></p-toast> 

