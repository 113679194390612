<ng-select [items]="allAcademicYears"
          style="width: 500px"
          placeholder="Выберите учебный год"
          bindLabel="name"
          [(ngModel)]="selectedAcademicYear"  
          (ngModelChange)="academicYearChanged()"                
          [clearable]="true">
        </ng-select>
<div class="pane">
  <button type="button" [disabled]="!selectedAcademicYear" class="btn btn-success" (click)="openModal(addAssociation)"><span class="glyphicon glyphicon-plus"></span> Добавить объединение в {{selectedAcademicYear?.name}} учебном году</button>
</div>

<table class="table">
  <thead>
    <tr>
      <th scope="col">Объединение</th>
      <th scope="col">Группы</th>
      <th scope="col">Педагог</th>
      <th scope="col">Действия</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let association of associations">
      <td>{{association.name}}</td>
      <td>
        <span class="badge" *ngFor="let group of association.groups" [title]="getStudentsInGroup(group)" style="cursor: default">
          {{group.name}} 
          <a href="javascript:void(0)" (click)="openEditGroupModal(editGroup, group)"><span style="color: white" class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
        </span> &nbsp;
      </td>
      <td>
        {{association.user?.fullName}}
      </td>
      <td>
        <div class="action-icons">
          <a title="Редактировать" href="javascript:void(0)" (click)="openEditModal(editAssociation, association)"><span style="color: purple" class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
          <a title="Удалить" href="javascript:void(0)" (click)="removeAss(association.id)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #editGroup>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Изменить состав группы</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">

      <app-student-selector 
        [allStudents]="students"
        [selectedStudents]="editedGroup.students"
        (selectedStudentsChanged)="updateStudentsInGroup($event)">
      </app-student-selector>


      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
          <button type="button" (click)="updateGroup()" class="btn btn-success">Сохранить</button>
      </div>
  </div>

</ng-template>


<ng-template #addAssociation>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Создать объединение</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">

      <div style="margin-top: -10px">
          <input type="text" [(ngModel)]="newAssociation.name" class="form-control" placeholder="Например, Секция по волейболу">
      </div>

      <br/>
      Педагог:
      <div class="group-selector">
          <ng-select
            [items]="users"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="fullName"
            placeholder="Выбор педагога"
            [(ngModel)]="newAssociation.user">
          </ng-select>
      </div>
      
      <br/>
      Выберите одну или несколько созданных ранее групп учеников, задействованных в объединении кликнув по этому полю:
      <div class="group-selector">
          <ng-select
            [items]="allGroupsByTypeAndYear"
            [multiple]="true"
            [closeOnSelect]="false"
            [searchable]="true"
            bindLabel="name"
            placeholder="Выбор групп"
            [(ngModel)]="selectedGroupsToCreateAssociation">
          </ng-select>
      </div>
    
      <br/>

      или создайте новые группы учеников сейчас:

      <br/>
      <div *ngFor="let newGroup of newGroups" style="padding: 10px 0;">
        <app-create-group [group]="newGroup" [students]="students"></app-create-group>
      </div>
      <br/>
      <a href="javascript:void(0)" (click)="createGroup()"> + Создать группу </a>

      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
          <button type="button" (click)="createAssociation()" class="btn btn-success">Создать</button>
      </div>
  </div>

</ng-template>


<ng-template #editAssociation>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Изменить информацию об объединении</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div style="margin-top: -10px">
          <input type="text" [(ngModel)]="newAssociation.name" class="form-control" placeholder="Например, Секция по волейболу">
      </div>
      
      <br/>
      Педагог:
      <div class="group-selector">
          <ng-select
            [items]="users"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="fullName"
            placeholder="Выбор педагога"
            [(ngModel)]="newAssociation.user">
          </ng-select>
      </div>

      <div style="text-align:center; margin-top: 15px;">
        <button type="button" (click)="edit()" class="btn btn-success">Сохранить</button>
    </div>

  </div>

</ng-template>
