<div style="margin-top: 10px;">
  <input type="checkbox" id="me" [(ngModel)]="showMine" (ngModelChange)="loadData()">
  <label style="font-weight: normal;" for="me">Показать только те мероприятия, в которых ответственен(на) я</label>
</div>
<br />
<input type="text"
       placeholder="Выберите день"
       class="form-control"
       [(ngModel)]="date"
       (ngModelChange)="loadData()"
       AUTOCOMPLETE="off"
       [bsConfig]="bsConfig"
       bsDatepicker
       style="width: 300px;">
<br />
<table class="table">
  <thead>
    <tr>
      <th scope="col">Направление деятельности</th>
      <th scope="col">Мероприятие</th>
      <th scope="col">Ответственные</th>
      <th scope="col">Форма подтверждения</th>
      <th scope="col">Действия</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let action of actions" class="c{{action.activity.color}}">
      <td>{{action.activity.name}}</td>
      <td>{{action.name}}</td>
      <td>
        <span *ngFor="let user of action.responsibles" class="badge">
          {{user.lastName}} {{user.firstName[0]}}. {{user.surName[0]}}.
        </span> &nbsp;
      </td>
      <td>{{action.confirmationForm.name}}</td>
      <td>
        <a *ngIf="checkRole(userState.currentUser?.state, 'Директор') || (checkRole(userState.currentUser?.state, 'Администратор') && action.status != 3)" title="Редактировать" href="javascript:void(0)" (click)="copy(action); openModal(edit);"><span style="color: purple" class="action-icon glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
        <a title="В тайм-лист" href="javascript:void(0)" (click)="copy(action); openModal(toTimeline);"><span style="color: purple" class="action-icon glyphicon glyphicon-time action-icons" aria-hidden="true"></span></a>
      </td>
    </tr>
  </tbody>
</table>

<div style="text-align: center; margin-top: 50px; font-style: italic;" *ngIf="!actions"><span>Выберите день</span></div>
<div style="text-align: center; margin-top: 50px; font-style: italic;" *ngIf="actions?.length == 0"><span>Мероприятий нет</span></div>


<ng-template #edit>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Редактировать мероприятие</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Дата:
    <input type="text"
           placeholder="Щелкните здесь для выбора"
           class="form-control"
           [(ngModel)]="bufferedAction.date"
           AUTOCOMPLETE="off"
           [bsConfig]="bsConfig"
           bsDatepicker>
    <br>
    Мероприятие:
    <input type="text" [(ngModel)]="bufferedAction.name" class="form-control" placeholder="Название мероприятия">
    <br>
    Ответственные:
    <ng-select [items]="allResponsibles | async"
               [multiple]="true"
               [closeOnSelect]="false"
               [searchable]="true"
               bindLabel="fullName"
               placeholder="Выберите как минимум одного"
               [(ngModel)]="bufferedAction.responsibles">
    </ng-select>
    <br>
    Форма подтверждения выполнения:
    <ng-select [items]="allConfForms | async"
               bindLabel="name"
               bindValue="id"
               [(ngModel)]="bufferedAction.confirmationForm.id"
               [clearable]="false">
    </ng-select>
    <br>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="deleteAction()" class="btn btn-danger">Удалить мероприяте</button>
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="saveAction()" class="btn btn-success">Сохранить</button>
    </div>

  </div>

</ng-template>


<ng-template #toTimeline>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Создать запись в тайм-листе</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px">
      Наименование:
      <input type="text" [(ngModel)]="newTicket.name" class="form-control" placeholder="Название записи">
    </div>

    <div>
      Дата:
      <input type="text"
             placeholder="Щелкните здесь для выбора"
             class="form-control"
             [(ngModel)]="newTicket.date"
             AUTOCOMPLETE="off"
             [bsConfig]="bsConfig"
             bsDatepicker>
    </div>
    <div>
      Время начала:
      <input type="text" [(ngModel)]="newTicket.hours" class="clock form-control" placeholder="ЧЧ"> :
      <input type="text" [(ngModel)]="newTicket.minutes" class="clock form-control" placeholder="ММ">
    </div>

    <div>
      <div class="inner-action-card c{{bufferedAction.activity.color}}">
        <span>{{bufferedAction.name}}</span>
        <br />
        <span *ngFor="let user of bufferedAction.responsibles" class="badge">{{user.lastName}} {{user.firstName[0]}}. {{user.surName[0]}}</span>
        <br />
        ФП: {{bufferedAction.confirmationForm.name}}
      </div>
    </div>

    <div>
      Комментарий(не обязательно):
      <textarea [(ngModel)]="newTicket.comment" class="form-control" placeholder="Комментарий"></textarea>
    </div>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="saveTicket()" class="btn btn-success">Сохранить</button>
    </div>

  </div>

</ng-template>

<p-toast></p-toast> 
