<div>
  <h1>Журнал внеурочной деятельности</h1>
  <app-register-params
  [allAcademicYears]="allAcademicYears"
  [associations]="associations"
  (selectedAcademicYearChanged)="loadAssociations($event)"
  (selectedAssociationChanged)="loadGroups($event)"
  (selectedGroupChanged)="updateSelectedGroup($event)"
  [groups]="groups">
</app-register-params>
<div *ngIf="selectedAssociation?.user">Педагог: {{selectedAssociation?.user?.fullName}}</div>
  <div class="tabs">
    <tabset>
      <tab heading="Журнал">
        <app-register-table 
          [academicYearId]="selectedAcademicYear?.id"
          [associationId]="selectedAssociation?.id"
          [groupId]="selectedGroup?.id"
          [rows]="registerRows" 
          (updateCellEvent)="updateCell($event)">
        </app-register-table>
      </tab>
      <tab heading="Календарно-тематическое планирование">
        <app-register-planing
        [selectedAcademicYear]="selectedAcademicYear"
        [selectedAssociation]="selectedAssociation"
        [selectedGroup]="selectedGroup"
        [records]="planingRecords"
        (ktpChanged)="loadRegister()"
      ></app-register-planing>
      </tab>
    </tabset>
  </div>
</div>