<table>
  <tr>
    <td>
      Учебный год:
      <ng-select [items]="allAcademicYears"
                 style="width: 500px;"
                 [(ngModel)]="selectedAcademicYearId"
                 placeholder="Щелкните здесь для выбора"
                 (ngModelChange)="academicYearChanged()"
                 bindValue="id"
                 bindLabel="name"
                 [clearable]="true">
      </ng-select>
    </td>
    <td>
      Объединение:
        <ng-select [items]="associations"
                   style="width: 500px;"
                   [(ngModel)]="selectedAssociationId"
                   (ngModelChange)="associationChanged()"
                   placeholder="Щелкните здесь для выбора"
                   bindValue="id"
                   bindLabel="name"
                   [clearable]="true">
        </ng-select>
    </td>
  </tr>
  <tr>
    <td>
      Группа:
      <ng-select [items]="groups"
                 style="width: 500px;"
                 placeholder="Щелкните здесь для выбора"
                 bindValue="id"
                 bindLabel="name"
                 [(ngModel)]="selectedGroupId"
                 (ngModelChange)="groupChanged()"
                 [clearable]="true">
      </ng-select>
    </td>
    <!-- <td>
      Период:
        <ng-select [items]="academicPeriods"
                   style="width: 500px;"
                   placeholder="Щелкните здесь для выбора"
                   bindValue="id"
                   bindLabel="name"
                   [clearable]="true">
        </ng-select>
    </td> -->
  </tr>
</table>      
      
        

        

        

        
