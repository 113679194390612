import { Dictionary } from "./dictionary.model";
import { User } from "./user";
import { Action } from "./action.model";
import { Time } from "./time.model";
import { Checklist } from "./checklist.model";
import { File } from "./file.model";
import { IncomingDocument } from "../../workflow/models/incoming-document.model";
import { OutgoingDocument } from "../../workflow/models/outgoing-document.mode";

export class Ticket extends Dictionary<number> {
  action: Action;
  comment: string;
  done: boolean;
  important: boolean;
  checklist: Checklist;
  date: Date;
  created: Date;
  hours: number;
  minutes: number;
  status: number;
  start: Time;
  end: Time;
  hasChecklist: boolean;
  notify: boolean;
  checklistId: number;
  userId: string;
  user: User;
  isExpiered: boolean;
  userIdsToAssignTicket: string[];
  autoGenerated: boolean;
  actionIsOutOfDate: boolean;
  responseComment: string;
  inFiles: File[];
  outFiles: File[];
  onCheck: boolean;
  incomingDocument: IncomingDocument;
  outgoingDocument: OutgoingDocument;

  //front only
  dateToGroup: any;
  dateToShow: string;
  blockedData: boolean;
  blockedTime: boolean;

  repeat: boolean;
  dateTo: Date;
  days: number[];
}
