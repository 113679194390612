<form #uploadForm><input type="file" (change)="uploadExcel(uploadForm)" #fileInput name="f" style="display:none" /></form>

<div class="pane">
  <button style="margin-left: 10px;" class="btn btn-info" (click)="openPreImportModal(preImportModal)">Импорт КТП из файла</button>
  <button style="margin-left: 10px;" class="btn btn-info" (click)="downloadTemplate()">Загрузить шаблон для импорта</button>
  <button class="btn btn-success" (click)="openAddModal(add)">Добавить запись КТП вручную</button>
</div>

<table class="table">
  <thead>
    <tr>
        <th scope="col">Дата</th>
        <th scope="col">Содержание/Тема</th>
        <th scope="col">Часы</th>
        <th scope="col">Примечание</th>
        <th scope="col">Действия</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let record of records">
      <td>{{record.date | date : 'dd.MM.yyyy'}}</td>
      <td>{{record.name}}</td>
      <td>{{record.hours}}</td>
      <td>{{record.comment}}</td>
      <td>
        <div class="action-icons">
          <a title="Редактировать" href="javascript:void(0)" (click)="openEditModal(edit, record)"><span style="color: purple" class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
          <a title="Удалить" href="javascript:void(0)" (click)="deleteRecord(record.id)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #preImportModal>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Параметры импорта КТП из Excel документа</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  
  <div class="modal-body pre-import-modal">
    Укажите количество записей в файле:
    <input type="number" [(ngModel)]="count" class="form-control" placeholder="10">

    <!-- Укажите диапазоны ячеек, в которых будет происходить считывание данных. <br/>
    Количество ячеек по каждому столбцу должно быть одинаковым! <br/>
    Например, вы указали диапазон ячеек по столбцу дат A1:A3, 
    следовательно диапазоны ячеек в других столбцах должны охватывать 3 ячейки.  
    <br/>
    <br/>
      Диапазон ячеек, содержащих дату:<br/>
      <div>
          <input type="text" onkeyup="this.value = this.value.toUpperCase();" [(ngModel)]="importModel.dateRange" class="form-control" placeholder="А1:A5">
      </div>
      Диапазон ячеек, содержащих тему:<br/> -->
      <!-- <div>
          <input type="text" onkeyup="this.value = this.value.toUpperCase();" [(ngModel)]="importModel.nameRange" class="form-control" placeholder="B1:B5">
      </div>
      Диапазон ячеек, содержащих часы:<br/>
      <div >
          <input type="text" onkeyup="this.value = this.value.toUpperCase();" [(ngModel)]="importModel.hoursRange" class="form-control" placeholder="C1:C5">
      </div>
      Диапазон ячеек, содержащих примечание:<br/>
      <div >
          <input type="text" onkeyup="this.value = this.value.toUpperCase();" [(ngModel)]="importModel.commentRange" class="form-control" placeholder="D1:D5">
      </div> -->

      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
          <button type="button" (click)="fileInput.click()" class="btn btn-success">Выбрать файл и начать импорт</button>
      </div>
  </div>

</ng-template>

<ng-template #add>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Добавить запись КТП</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px">
      Дата:
      <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" [(ngModel)]="newRecord.date"
             AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
    </div>
    <br />
    <div>
      Содержание/Тема:
      <input type="text" [(ngModel)]="newRecord.name" class="form-control" placeholder="Название задания">
    </div>
    <br />
    <div>
      Часы:
      <input type="text" [(ngModel)]="newRecord.hours" class="form-control" placeholder="Часы">
    </div>
    <br />
    <div>
      Комментарий:
      <textarea [(ngModel)]="newRecord.comment" class="form-control" placeholder="Комментарий"></textarea>
    </div>
    <br />
    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="addRecord()" class="btn btn-success">Добавить</button>
    </div>
  </div>
</ng-template>

<ng-template #edit>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Изменить запись КТП</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px">
      Дата:
      <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" [(ngModel)]="editedRecord.date"
             AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
    </div>
    <br />
    <div>
      Содержание/Тема:
      <input type="text" [(ngModel)]="editedRecord.name" class="form-control" placeholder="Название задания">
    </div>
    <br />
    <div>
      Часы:
      <input type="text" [(ngModel)]="editedRecord.hours" class="form-control" placeholder="Часы">
    </div>
    <br />
    <div>
      Комментарий:
      <textarea [(ngModel)]="editedRecord.comment" class="form-control" placeholder="Комментарий"></textarea>
    </div>
    <br />
    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="saveEditedRecord()" class="btn btn-success">Сохранить</button>
    </div>
  </div>
</ng-template>

<p-toast></p-toast>