<h1>Чек-листы</h1>

 <div class="panel panel-default">
          <div class="panel-body">
              <div style="text-align: left">
                  <button type="button" (click)="clear(); openModal(add)" class="btn btn-success"><span class="glyphicon glyphicon-plus"></span> Создать чек-лист</button>
              </div>
          </div>
</div>

<div *ngIf="!checklists && !otherChecklists" style="text-align: center; margin-top: 50px; font-style: italic;"><span class="loading">Загрузка</span></div>


<tabset *ngIf="checklists || otherChecklists">
  <tab heading="Мои чек-листы" id="tab1">

<div *ngIf="checklists?.length == 0" style="text-align: center; margin-top: 50px; font-style: italic;"><span>У вас пока нет ни одного чек-листа</span></div>

<div style="display: flex; flex-direction: row; flex-wrap: wrap; margin-left: -5px; margin-top: 15px;">
    <div *ngFor="let checklist of checklists" class="panel panel-default"
        style="max-width: 440px; min-width: 440px; margin: 5px">
        <div class="panel-heading" style="display: flex; justify-content: space-between">
            <div style="word-wrap: break-word; width: 90%; font-weight: bold;">{{checklist.name}} <span *ngIf="checklist.unseenCount > 0" class="badge badge-info">{{checklist.unseenCount}}</span></div>
            <div style="width: 15px; display: inline-block;">
                <a title="Редактировать" href="javascript:void(0)" (click)="copy(checklist); openModal(edit);">
                    <span style="color: purple" class="glyphicon glyphicon-pencil" aria-hidden="true">
                    </span>
                </a>
            </div>
        </div>
        <div class="panel-body">
            <div>
                <div *ngIf="!(checklist.assignedCount == 0 && checklist.acceptedCount == 0 && checklist.doneCount == 0)"
                    style="width: 120px; float: left;">
                    <p-chart [width]="250" type="doughnut" [options]="checklist.chartData.options"
                        [data]="checklist.chartData">
                    </p-chart>
                </div>

                <div
                    style="text-align: left; display: inline-block; max-width: 260px; margin-left: 10px; margin-top: 2px;">
                    <div>
                        <p>Всего заданий: {{checklist.totalCount}}</p>
                    </div>
                    <div>
                        <p>Срок выполнения: {{checklist.deadline | date : 'dd.MM.yyyy'}}</p>
                    </div>
                    <div>
                        <p *ngIf="checklist.expieredCount" style="color: #ed143d">Просроченные задания: {{checklist.expieredCount}}</p>
                    </div>
                    <div style="word-wrap: break-word;">
                        <p *ngIf="checklist.comment">Комментарий: {{checklist.comment}}</p>
                    </div>
                </div>
            </div>
            <div>
                <button style="margin-top: 25px;" type="button"
                    [routerLink]="['//scheduler/checklist-details', checklist.id]"
                    class="btn-tasks btn btn-info btn-block">Перейти к заданиям чеклиста</button>
            </div>

        </div>
    </div>
</div>
  </tab>
  <tab *ngIf="userState.currentUser?.state.canSeeAllChecklists" heading="Чек-листы других пользователей" id="tab2">
      <div *ngIf="otherChecklistKeys" style="margin-top: 20px">
          <div  *ngFor="let key of otherChecklistKeys">
              <app-other-checklists [checklists]="getOtherChecklistArrayByKey(key)"
                                    [title]="key">
              </app-other-checklists>
          </div>
      </div>
  </tab>
</tabset>

<ng-template #add>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Создание чек-листа</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="margin-top: -10px">
            Наименование:
            <input type="text" [(ngModel)]="newChecklist.name" class="form-control" placeholder="Название чек-листа">
        </div>

        <div>
            Срок выполнения:
            <input type="text" placeholder="Щелкните здесь для выбора" class="form-control"
                [(ngModel)]="newChecklist.deadline" AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
        </div>

        <div>
            Комментарий(не обязательно):
            <textarea [(ngModel)]="newChecklist.comment" class="form-control" placeholder="Комментарий"></textarea>
        </div>

        <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
            <button type="button" (click)="addChecklist()" class="btn btn-success">Сохранить</button>
        </div>

    </div>
</ng-template>

<ng-template #edit>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Редактирование чек-листа</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <br />
    <div class="modal-body">
        <div style="margin-top: -10px">
            Наименование:
            <input type="text" [(ngModel)]="newChecklist.name" class="form-control" placeholder="Название чек-листа">
        </div>
        <br />
        <div>
            Срок выполнения:
            <input type="text" placeholder="Щелкните здесь для выбора" class="form-control"
                [(ngModel)]="newChecklist.deadline" AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
        </div>
        <br />
        <div>
            Комментарий(не обязательно):
            <textarea [(ngModel)]="newChecklist.comment" class="form-control" placeholder="Комментарий"></textarea>
        </div>
        <br />
        <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="deleteChecklist()" class="btn btn-danger">Удалить чек-лист</button>
            <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
            <button type="button" (click)="saveChecklist()" class="btn btn-success">Сохранить</button>
        </div>

    </div>
</ng-template>


<p-toast></p-toast> 
