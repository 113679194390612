<div style="margin: 20px 15px;">
    <div class="protocol-buttons">
        <div>
            <a routerLink="//scheduler/protocols" style="text-decoration: none;"><span class="glyphicon glyphicon-arrow-left"></span> Вернуться к протоколам</a>
        </div>
        <div>
            <button [disabled]="!protocol" (click)="getDocument()" type="button" class="btn btn-primary"><span class="glyphicon glyphicon-file" aria-hidden="true"></span> Вывести документ</button>
        </div>
    </div>

    <div *ngIf="!protocol" style="text-align: center; margin-top: 50px; font-style: italic;"><span class="loading">Пожалуйста, подождите</span></div>

    <div *ngIf="protocol" class="protocol-container">
        <!--<div class="protocol-header protocol-element">
            Муниципальное казенное общеобразовательное учреждение<br />
            «Ширинская средняя общеобразовательная школа»<br />
            (МКОУ «Ширинская СОШ»)
        </div>-->

        <div class="protocol-element" style="margin-top: 50px; margin-bottom: 50px">
            ПРОТОКОЛ
        </div>

        <div class="protocol-element underheader">
            <div>
                {{protocol.action.date | date : 'dd.MM.yyyy'}}
            </div>
            <div>
                № <input type="text" placeholder="1" style="width: 65px" [(ngModel)]="protocol.number" />
            </div>
        </div>

        <div class="protocol-body protocol-element">
            <div class="protocol-field">
                заседания <input type="text" placeholder="Педагогического совета" [(ngModel)]="protocol.name" />
            </div>

            <div class="protocol-field">
                Председатель - <input type="text" placeholder="Фамилия И.О." [(ngModel)]="protocol.chairman" />
            </div>

            <div class="protocol-field">
                Секретарь - <input type="text" placeholder="Фамилия И.О." [(ngModel)]="protocol.secretary" />
            </div>

            <div class="protocol-field" style="display: flex;">
                Присутствовали: <input type="text" placeholder="N человек" style="margin-left: 5px;" [(ngModel)]="protocol.attended" />
            </div>

            <div class="protocol-element" style="margin-top: 25px; text-align: center">
                ПОВЕСТКА ДНЯ:
            </div>

            <div class="protocol-field agenda-table">
                <table style="width: 100%">
                    <tr *ngFor="let agenda of protocolContent">
                        <td style="width: 10%; vertical-align: text-top;">{{protocolContent.indexOf(agenda) + 1}} <br /> <a href="javascript:void(0)" style="font-size: 12px;" class="delete-row" (click)="delAgenda(agenda)">(удалить строку)</a></td>
                        <td style="width: 60%"><textarea placeholder="Текст" [(ngModel)]="agenda.content" style="width: 100%; resize: none"></textarea></td>
                        <td style="width: 30%; vertical-align: text-top;">
                            <ng-select [items]="allResponsibles | async"
                                       [multiple]="false"
                                       [closeOnSelect]="true"
                                       [searchable]="true"
                                       bindLabel="fullName"
                                       placeholder="Фамилия И.О."
                                       [(ngModel)]="agenda.author"
                                       addTagText="Добавить " 
                                       [addTag]="true"
                                       >
                            </ng-select>
                            <input *ngIf="agenda.author" type="text" placeholder="Введите должность" [(ngModel)]="agenda.author.position" style="width: 100%"/>
                        </td>
                    </tr>
                </table>

                <a href="javascript:void(0)" style="text-decoration: none; margin-top: 20px; font-size: 15px;" (click)="addAgenda()"><span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Добавить выступление</a>
            </div>

            <div *ngFor="let agenda of protocolContent">
                <div class="protocol-element" style="margin-top: 25px; text-align: left">
                    {{protocolContent.indexOf(agenda) + 1}}. СЛУШАЛИ:
                </div>
                <div *ngFor="let listen of agenda.listen">
                    <ng-select [items]="allResponsibles | async"
                               [multiple]="false"
                               [closeOnSelect]="true"
                               [searchable]="true"
                               bindLabel="fullName"
                               placeholder="Фамилия И.О."
                               [(ngModel)]="listen.user"
                               addTagText="Добавить " 
                               [addTag]="true">
                    </ng-select>
                    <a href="javascript:void(0)" style="font-size: 12px;" class="delete-listen-row" (click)="delListen(agenda, listen)">(удалить выступающего)</a>
                    <div class="protocol-field">
                        <textarea placeholder="Введите текст" [(ngModel)]="listen.content"></textarea>
                    </div>
                </div>
                <a href="javascript:void(0)" style="text-decoration: none; margin-top: 20px; font-size: 15px;" (click)="addListen(agenda)"><span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Добавить выступающего</a>
                <div class="protocol-element" style="margin-top: 25px; text-align: left">
                    ВЫСТУПИЛИ:
                </div>
                <div *ngFor="let speaked of agenda.speaked">
                    <ng-select [items]="allResponsibles | async"
                               [multiple]="false"
                               [closeOnSelect]="true"
                               [searchable]="true"
                               bindLabel="fullName"
                               placeholder="Фамилия И.О."
                               addTagText="Добавить " 
                               [addTag]="true"
                               [(ngModel)]="speaked.user">
                    </ng-select>
                    <a href="javascript:void(0)" style="font-size: 12px;" class="delete-speaked-row" (click)="delSpeaked(agenda, speaked)">(удалить выступающего)</a>
                    <div class="protocol-field">
                        <textarea placeholder="Введите текст" [(ngModel)]="speaked.content"></textarea>
                    </div>
                </div>
                <a href="javascript:void(0)" style="text-decoration: none; margin-top: 20px; font-size: 15px;" (click)="addSpeaked(agenda)"><span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Добавить выступающего</a>
                <div>
                    <div class="protocol-element" style="margin-top: 25px; text-align: left">
                        РЕШИЛИ (ПОСТАНОВИЛИ):
                    </div>
                    <div class="protocol-field">
                        <textarea placeholder="Введите текст" [(ngModel)]="agenda.decided"></textarea>
                    </div>
                </div>
            </div>

            <!--<div class="protocol-footer">
                    <div class="protocol-field" style="display: flex; justify-content: space-between">
                        <div style="width: 50px">
                            Председатель
                        </div>
                        <div>
                            Подпись
                        </div>
                        <div>
                            Фамилия ИО
                        </div>
                    </div>
                    <div class="protocol-field" style="display: flex; justify-content: space-between">
                        <div style="width: 50px">
                            Секретарь
                        </div>
                        <div>
                            Подпись
                        </div>
                        <div>
                            Фамилия ИО
                        </div>
                    </div>
                </div>-->

        </div>
    </div>

    <div *ngIf="protocol" style="margin: 50px; text-align: center">
        <button type="button" class="btn btn-success" style="font-size: 17px" (click)="saveProtocol()">Сохранить протокол</button>
    </div>

</div>


<p-toast></p-toast> 
