<div *ngIf="!checklist?.tickets" style="text-align: center; margin-top: 50px; font-style: italic;"><span class="loading">Загрузка</span></div>
<div *ngIf="checklist?.tickets">
<div style="word-wrap: break-word;">
  <h1>{{checklist?.name}}</h1>
</div>

<div class="panel panel-default">
    <div class="panel-body">
        <div style="text-align: left">
            <button type="button" (click)="clear(); openModal(add)" class="btn btn-success"><span class="glyphicon glyphicon-plus"></span> Добавить задание</button>
        </div>
        <div style="margin-top:15px; width: 350px; display: flex">
            <input type="checkbox" id="cto" (change)="filterTicketsByNotifyStatus($event)">
            <label style="font-weight: normal;" for="cto">Отобразить только требующие внимания задания</label>
        </div>
    </div>
</div>



<br />

<h6 *ngIf="checklist?.tickets && checklist?.tickets.length != 0" style="text-align: right">Страница {{currentPage}} из {{totalPages}}</h6>

<table class="table" >
  <thead>
    <tr>
      <th scope="col" style="width: 8%">
        <a href="javascript:void(0)" style="text-decoration: unset; color: #333;" (click)="sort('Created')"><span *ngIf="sortProperty == 'Created'" style="font-size: 10px;"><span *ngIf="sortDirection == 0" class='glyphicon glyphicon-arrow-up'></span><span *ngIf="sortDirection == 1" class='glyphicon glyphicon-arrow-down'></span></span> Дата создания</a>
        <input [(ngModel)]="filter.created" (ngModelChange)="currentPage = 1; loadData(false)" type="text" class="form-control" />
      </th>
      <th scope="col" style="width: 8%">
        <a href="javascript:void(0)" style="text-decoration: unset; color: #333;" (click)="sort('Date')"><span *ngIf="sortProperty == 'Date'" style="font-size: 10px;"><span *ngIf="sortDirection == 0" class='glyphicon glyphicon-arrow-up'></span><span *ngIf="sortDirection == 1" class='glyphicon glyphicon-arrow-down'></span></span> Дата выполнения</a>
        <input [(ngModel)]="filter.date" (ngModelChange)="currentPage = 1; loadData(false)" type="text" class="form-control" />
      </th>
      <th scope="col" style="width: 5%; vertical-align: middle; text-align: center"><span>Время</span></th>
      <th scope="col" style="width: 25%; vertical-align: bottom; text-align: center"><span>Наименование</span>
        <input [(ngModel)]="filter.name" (ngModelChange)="currentPage = 1; loadData(false)" type="text" class="form-control" />
      </th>
      <th scope="col" style="width: 25%; vertical-align: bottom; text-align: center"><span>Комментарий</span>
        <input [(ngModel)]="filter.comment" (ngModelChange)="currentPage = 1; loadData(false)" type="text" class="form-control" />
      </th>
      <th scope="col" style="width: 15%; vertical-align: bottom; text-align: center"><span>Кому назначена</span>
        <ng-select [items]="responsibles | async"
               [multiple]="false"
               [closeOnSelect]="true"
               [searchable]="true"
               bindLabel="fullName"
               bindValue="id"
               [(ngModel)]="filter.userId"
               (ngModelChange)="currentPage = 1; loadData(false)"
               appendTo="body"
               style="font-weight: normal">
        </ng-select>
      </th>
      <th scope="col" style="width: 7%; vertical-align: bottom; text-align: center"><span>Статус</span>
        <ng-select [items]="statuses"
               [multiple]="false"
               [closeOnSelect]="true"
               [searchable]="true"
               bindLabel="name"
               bindValue="id"
               [(ngModel)]="filter.status"
               (ngModelChange)="currentPage = 1; loadData(false)"
               appendTo="body"
               style="font-weight: normal">
        </ng-select>
      </th>
      <th scope="col" style="width: 7%; vertical-align: middle; text-align: center"><span>Действия</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ticket of checklist?.tickets"
        [class.bold]="ticket.notify"
        [class.blue]="ticket.status == 1"
        [class.red]="ticket.isExpiered && ticket.status != 3"
        [class.yellow]="ticket.status == 3"
        [class.green]="ticket.status == 4">
      <td><span *ngIf="ticket.created != '0001-01-01T00:00:00'">{{ticket.created | date : 'dd.MM.yyyy'}}</span></td>
      <td>{{ticket.date | date : 'dd.MM.yyyy'}}</td>
      <td><span *ngIf="ticket.hours">{{ticket.hours}}:{{ticket.minutes}}<span *ngIf="ticket.minutes == 0">0</span></span></td>
      <td>
        <a style="color: black" href="javascript:void(0)" (click)="openDetails(ticket, detailsModal)">
            <span title="Исполнитель прокомментировал это задание или добавил вложения" *ngIf="ticket.notify" style="color: darkcyan" class="glyphicon glyphicon-comment" aria-hidden="true"></span> {{ticket.name}}
        </a>
      </td>
      <td>
        <span *ngIf="ticket.comment" (click)="copy(ticket); openFullCommentModal()" style="cursor: pointer;">
          {{getCutComment(ticket.comment)}}
        </span>
      </td>
      <td>
        <span *ngIf="ticket.user.firstName" class="badge">
          {{ticket.user.lastName}} {{ticket.user.firstName[0]}}. {{ticket.user.surName[0]}}.
        </span>
      </td>
      <td>
        <span title="Задание не назначено никому из ваших коллег на данный момент" *ngIf="ticket.status == 0">Не назначено</span>
        <span title="Задание находится в списке новых заданий тайм-листа и оно еще не взято в работу" *ngIf="ticket.status == 1">Назначено</span>
        <span title="Задание занесено в тайм-лист и взято в работу" *ngIf="ticket.status == 2">Принято</span>
        <span title="Задание было отклонено или удалено из тайм-листа" *ngIf="ticket.status == 3">Отклонено</span>
        <span title="Задание было выполнено" *ngIf="ticket.status == 4">Готово</span>
      </td>
      <td>
        <div style="text-align: center" class="action-icons">
          <a title="Отметка &laquo;Выполнено&raquo;" href="javascript:void(0)" (click)="makeDone(ticket)"><span [class.gray]="!ticket.done" class="glyphicon glyphicon-ok" aria-hidden="true"></span></a>
          <a title="Редактировать" href="javascript:void(0)" (click)="copy(ticket); openModal(edit)">
            <span style="color: purple" class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
          </a>
          <a title="Удалить" href="javascript:void(0)" (click)="delete(ticket)">
            <span style="color: red"
                  class="glyphicon glyphicon-remove" aria-hidden="true"></span>
          </a>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div style="text-align: center; margin-top: 50px; font-style: italic;"
     *ngIf="!checklist?.tickets || checklist?.tickets.length == 0"><span>Заданий нет</span></div>

<div *ngIf="checklist?.tickets && checklist?.tickets.length != 0 && paginatorVisibility" style="text-align: center;">
  <pagination [boundaryLinks]="showBoundaryLinks()" firstText="В начало" lastText="В конец" nextText="Далее" previousText="Назад" [totalItems]="totalItemCount" [(ngModel)]="currentPage" (pageChanged)="currentPage = $event.page; loadData(true, true)" [maxSize]="5" [itemsPerPage]="15"></pagination>
</div>
</div>

<ng-template #detailsModal>
    <div class="modal-header">
      <h4 style="width: 95%;" class="modal-title pull-left">{{newTicket.name}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div>
          <h4 style="font-size: 15px;">Ваш комментарий и вложения:</h4>
          <textarea style="height: 200px" [(ngModel)]="newTicket.comment" class="form-control" placeholder="Ваш комментарий"></textarea>
          <br/>
            <table class="table" *ngIf="newTicket.inFiles.length != 0">
                <thead>
                  <tr>
                    <th scope="col" style="width: 80%">Файл</th>
                    <th scope="col" style="width: 10%">Размер</th>
                    <th scope="col" style="width: 10%; text-align: center">Действия</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let file of newTicket.inFiles">
                    <td>
                        <i *ngIf="file.extension == 'doc' || file.extension == 'docx'" class="fa fa-file-word-o" aria-hidden="true" style="color: blue"></i>
                        <i *ngIf="file.extension == 'xls' || file.extension == 'xlsx'" class="fa fa-file-excel-o" aria-hidden="true" style="color: green"></i>
                        <i *ngIf="file.extension == 'ppt' || file.extension == 'pptx'" class="fa fa-file-powerpoint-o" aria-hidden="true" style="color: orange"></i>
                        <i *ngIf="file.extension == 'pdf'" class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i>
                        <i *ngIf="file.extension == 'zip' || file.extension == 'rar'" class="fa fa-file-archive-o" aria-hidden="true" style="color: dimgray"></i>
                        <i *ngIf="file.extension == 'png' || file.extension == 'jpg' || file.extension == 'jpeg'" class="fa fa-file-image-o" aria-hidden="true" style="color: purple"></i>
                        <i *ngIf="file.extension != 'doc'
                           && file.extension != 'docx'
                           && file.extension != 'xls'
                           && file.extension != 'xlsx'
                           && file.extension != 'ppt'
                           && file.extension != 'pptx'
                           && file.extension != 'pdf'
                           && file.extension != 'zip'
                           && file.extension != 'rar'
                           && file.extension != 'png'
                           && file.extension != 'jpg'
                           && file.extension != 'jpeg'"
                           class="fa fa-file-o" aria-hidden="true"></i>
                           {{file.name}}
                    </td>
                    <td>{{file.sizeMb}} Мб</td>
                    <td style="text-align: center">
                      <a title="Скачать" (click)="downloadFile(file.id)" href="javascript:void(0)"><span style="color: green" class="glyphicon glyphicon-save" aria-hidden="true"></span></a>
                      <a title="Удалить" href="javascript:void(0)" (click)="deleteFileBinding(file, newTicket)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div>
                  <kendo-upload [saveUrl]="fileUploadUrl"
                                [removeUrl]="fileRemoveUrl"
                                (upload)="uploadEventHandler($event)"
                                (remove)="removeEventHandler($event)"
                                (success)="uploaded($event)">
                    <kendo-upload-messages select="Добавить вложение"
                                           uploadSelectedFiles="Загрузить выбранные файлы"
                                           clearSelectedFiles="Удалить выбранные файлы">
                    </kendo-upload-messages>
                  </kendo-upload>
              </div>
        </div>

        <br/>

        <div *ngIf="newTicket.outFiles.length != 0 || newTicket.responseComment">
            <h4 style="font-size: 15px;">Ответный комментарий и вложения:</h4>
            <p>{{newTicket.responseComment}}</p>
        </div>
       
        <div>
              <table class="table" *ngIf="newTicket.outFiles.length != 0">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 80%">Файл</th>
                      <th scope="col" style="width: 10%">Размер</th>
                      <th scope="col" style="width: 10%; text-align: center">Действия</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let file of newTicket.outFiles">
                      <td>
                          <i *ngIf="file.extension == 'doc' || file.extension == 'docx'" class="fa fa-file-word-o" aria-hidden="true" style="color: blue"></i>
                          <i *ngIf="file.extension == 'xls' || file.extension == 'xlsx'" class="fa fa-file-excel-o" aria-hidden="true" style="color: green"></i>
                          <i *ngIf="file.extension == 'ppt' || file.extension == 'pptx'" class="fa fa-file-powerpoint-o" aria-hidden="true" style="color: orange"></i>
                          <i *ngIf="file.extension == 'pdf'" class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i>
                          <i *ngIf="file.extension == 'zip' || file.extension == 'rar'" class="fa fa-file-archive-o" aria-hidden="true" style="color: dimgray"></i>
                          <i *ngIf="file.extension == 'png' || file.extension == 'jpg' || file.extension == 'jpeg'" class="fa fa-file-image-o" aria-hidden="true" style="color: purple"></i>
                          <i *ngIf="file.extension != 'doc'
                             && file.extension != 'docx'
                             && file.extension != 'xls'
                             && file.extension != 'xlsx'
                             && file.extension != 'ppt'
                             && file.extension != 'pptx'
                             && file.extension != 'pdf'
                             && file.extension != 'zip'
                             && file.extension != 'rar'
                             && file.extension != 'png'
                             && file.extension != 'jpg'
                             && file.extension != 'jpeg'"
                             class="fa fa-file-o" aria-hidden="true"></i>
                             {{file.name}}
                      </td>
                      <td>{{file.sizeMb}} Мб</td>
                      <td style="text-align: center">
                        <a title="Скачать" (click)="downloadFile(file.id)" href="javascript:void(0)"><span style="color: green" class="glyphicon glyphicon-save" aria-hidden="true"></span></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
          </div>
  
        
          <div style="text-align:center; margin-top: 15px;">
            <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
            <button type="button" (click)="saveReply()" class="btn btn-success">Сохранить</button>
          </div>
      </div>
  </ng-template>


<ng-template #add>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Создать задание</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px">
      Наименование:
      <input type="text" [(ngModel)]="newTicket.name" class="form-control" placeholder="Название задания">
    </div>
    <br />
    <div>
      Дата выполнения:
      <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" [(ngModel)]="newTicket.date"
             AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
    </div>

    <div style="margin-top: 20px;">
      Время начала:
      <input type="text" [(ngModel)]="newTicket.hours" class="clock form-control" placeholder="ЧЧ"> :
      <input type="text" [(ngModel)]="newTicket.minutes" class="clock form-control" placeholder="ММ">
    </div>
    <br />

    Кому назначено:
    <ng-select [items]="responsibles | async"
               [multiple]="true"
               [closeOnSelect]="false"
               [searchable]="true"
               bindLabel="fullName"
               bindValue="id"
               placeholder="Вы можете сделать выбор позже"
               [(ngModel)]="newTicket.userIdsToAssignTicket">
    </ng-select>
    <br />
    <div>
      Комментарий:
      <textarea [(ngModel)]="newTicket.comment" class="form-control" placeholder="Комментарий"></textarea>
    </div>
    <br />

    <div>
      <kendo-upload [saveUrl]="fileUploadUrl" 
                    [removeUrl]="fileRemoveUrl"
                    (upload)="uploadEventHandler($event)"
                    (remove)="removeEventHandler($event)"
                    (success)="uploaded($event)">
        <kendo-upload-messages select="Добавить вложение"
                               uploadSelectedFiles="Загрузить выбранные файлы"
                               clearSelectedFiles="Удалить выбранные файлы">
        </kendo-upload-messages>
      </kendo-upload>
    </div>

    <div *ngIf="showAutoAcceptMessage()" style="margin-top: 15px">
        <div class="alert alert-info" role="alert">
            <strong>Это задание будет занесено в ваш тайм-лист автоматически</strong>
            <p>Так как вы указали дату и время выполнения, а также назначили это задание на себя, то вам не нужно его принимать в ваш тайм-лист.</p>
            <p>Оно сразу появится там, когда вы нажмете кнопку "Сохранить" в этом окне</p>
        </div>
    </div>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="addTicket(null)" class="btn btn-success">Сохранить</button>
    </div>

  </div>
</ng-template>

<ng-template #edit>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Изменить задание</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px">
      Наименование:
      <input type="text" [(ngModel)]="newTicket.name" class="form-control" placeholder="Название записи">
    </div>
    <br />
    <div>
      Дата выполнения:
      <input type="text"
             placeholder="Щелкните здесь для выбора"
             class="form-control"
             [(ngModel)]="newTicket.date"
             AUTOCOMPLETE="off"
             [bsConfig]="bsConfig"
             bsDatepicker>
    </div>
    <br />
    <div>
      Время начала:
      <input type="text" [(ngModel)]="newTicket.hours" class="clock form-control" placeholder="ЧЧ"> :
      <input type="text" [(ngModel)]="newTicket.minutes" class="clock form-control" placeholder="ММ">
    </div>

    <br />

    Кому назначено:
    <ng-select [items]="responsibles | async"
               [multiple]="false" [closeOnSelect]="true" [searchable]="true" bindLabel="fullName"
               placeholder="Выберите только одного" [(ngModel)]="newTicket.user">
    </ng-select>
    <br />

    <div>
      Комментарий:
      <textarea style="height: 200px" [(ngModel)]="newTicket.comment" class="form-control" placeholder="Комментарий"></textarea>
    </div>
    <br />

    <br />
    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="saveTicket()" class="btn btn-success">Сохранить</button>
    </div>


  </div>

</ng-template>

<ng-template #fullComment>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Полный комментарий</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <textarea readonly class="full-comment-textarea">{{this.newTicket.comment}}</textarea>
  </div>
</ng-template>

<p-toast></p-toast> 
