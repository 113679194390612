<div class="up-control">
  <ng-select [items]="allAcademicYears"
    style="width: 500px"
    placeholder="Выберите учебный год"
    bindLabel="name"
    [(ngModel)]="selectedAcademicYear"  
    (ngModelChange)="academicYearChanged()"                
    [clearable]="true">
  </ng-select>

  <button type="button" [disabled]="!selectedAcademicYear" (click)="openModal(add)" class="btn btn-success top-item"><span class="glyphicon glyphicon-plus"></span> Создать класс в {{selectedAcademicYear?.name}} учебном году</button>&nbsp;
</div>

<div *ngFor="let class of classesWithStudents">
  <div class="class-label">
    <h3>{{class.name}}</h3> 
    <a title="Удалить класс" (click)="deleteClass(class, deleteModal)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
  </div>
  
  <button type="button" (click)="classIdToBindStudents = class.id; openModal(addStudents)" class="btn btn-link top-item"><span class="glyphicon glyphicon-plus"></span> Добавить учеников </button>&nbsp;
      <div *ngIf="class.students && class.students.length > 0">
        <table class="table">
          <thead>
            <tr>
                <th scope="col" style="width: 5%">#</th>
                <th scope="col" style="width: 45%">ФИО</th>
                <th scope="col" style="width: 25%">Дата рождения</th>
                <th scope="col" style="width: 25%">Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let student of class.students">
              <td>{{class.students.indexOf(student) + 1}}</td>
              <td>{{student.fullName}}</td>
              <td>{{student.birthday | date : 'dd.MM.yyyy'}}</td>
              <td>
                <a title="Убрать из списка класса в выбранном учебном году" (click)="excludeStudentFromClass(student.id, class.id)" href="javascript:void(0)"><span style="color: rgb(255, 150, 45)" class="glyphicon glyphicon-minus-sign action-icons" aria-hidden="true"></span></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
</div>

<ng-template #add>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Создать класс</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div style="margin-top: -10px">
          <input type="text" [(ngModel)]="newClass.name" class="form-control" placeholder="Например, 5Б класс">
      </div>

      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
          <button type="button" (click)="createClass()" class="btn btn-success">Создать класс</button>
      </div>
  </div>

</ng-template>

<ng-template #deleteModal>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Удалить {{classToDelete.name}}?</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div style="margin-top: -10px">
          В {{selectedAcademicYear.name}} учебном году выбранный класс будет удален. Продолжить?
      </div>

      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-success">Отмена</button>
          <button type="button" (click)="delete(classToDelete.id)" class="btn btn-danger">Удалить класс</button>
      </div>
  </div>

</ng-template>


<ng-template #addStudents>
  <div class="modal-header"> 
      <h4 class="modal-title pull-left">Добавить учеников</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      
    <app-student-selector 
        [allStudents]="studentService.students"
        (selectedStudentsChanged)="updateStudentsToAdd($event)">
    </app-student-selector>

      <div style="text-align:center; margin-top: 15px;" *ngIf="this.studentsToAdd.length > 0">
          <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
          <button type="button" (click)="bindStudentsToClass()" class="btn btn-success">Добавить выбранных учеников в класс</button>
      </div>
  </div>

</ng-template>