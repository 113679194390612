<div class="container" style="padding-top: 20px">

<a routerLink="/" style="text-decoration: none;"><span class="glyphicon glyphicon-arrow-left"></span> Вернуться к приложениям</a>


<h1>Настройки</h1>

<tabset>
    <tab heading="Управление доступом" id="tab1">
        <br />
        <button type="button" (click)="openModal(add)" class="btn btn-success"><span class='glyphicon glyphicon-plus'></span> Добавить нового пользователя</button>
        <br />
        <div *ngIf="!users" style="text-align: center; margin-top: 50px; font-style: italic;"><span class="loading">Загрузка</span></div>

        <table class="table" *ngIf="users">
            <thead>
                <tr>
                    <th scope="col">ФИО</th>
                    <th scope="col">Логин</th>
                    <th scope="col">Роль</th>
                    <th scope="col" width="9%">Согласование мероприятий</th>
                    <th scope="col" width="9%">Утверждение мероприятий</th>
                    <th scope="col" width="9%">Ведение чек-листов</th>
                    <th scope="col" width="9%"><span title="Возможность видеть и редактировать чек-листы всех сотрудников" aria-hidden="true">Все чек-листы школы</span></th>
                    <th scope="col" width="9%"><span title="Возможность видеть и редактировать все протоколы мероприятий в школе" aria-hidden="true">Все протоколы школы</span></th>
                    <th scope="col" width="9%"><span title="Возможность видеть и редактировать планы мероприятий всех сотрудников" aria-hidden="true">Все планы школы</span></th>
                    <th style="text-align: center;" scope="col">Действия</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                    <td>{{user.lastName}} {{user.firstName}} {{user.surName}}</td>
                    <td>
                        {{user.name}}
                    </td>
                    <td>
                        <span *ngFor="let role of user?.roles">
                            {{role}}
                        </span> &nbsp;
                    </td>
                    <td style="text-align: center">
                        <input type="checkbox" [(ngModel)]="user.canConfirm" (ngModelChange)="setPermission(user, 'CanConfirm', user.canConfirm)"/>
                    </td>
                    <td style="text-align: center">
                        <input type="checkbox" [(ngModel)]="user.canAccept" (ngModelChange)="setPermission(user, 'CanAccept', user.canAccept)"/>
                    </td>
                    <td style="text-align: center">
                        <input type="checkbox" [(ngModel)]="user.canUseChecklists" (ngModelChange)="setPermission(user, 'CanUseChecklists', user.canUseChecklists)"/>
                    </td>
                    <td style="text-align: center">
                        <input type="checkbox" [(ngModel)]="user.canSeeAllChecklists" (ngModelChange)="setPermission(user, 'CanSeeAllChecklists', user.canSeeAllChecklists)"/>
                    </td>
                    <td style="text-align: center">
                        <input type="checkbox" [(ngModel)]="user.canSeeAllProtocols" (ngModelChange)="setPermission(user, 'CanSeeAllProtocols', user.canSeeAllProtocols)"/>
                    </td>
                    <td style="text-align: center">
                        <input type="checkbox" [(ngModel)]="user.canSeeAllSchedules" (ngModelChange)="setPermission(user, 'CanSeeAllSchedules', user.canSeeAllSchedules)"/>
                    </td>
                    <td>
                        <div style="text-align: center">
                            <a title="Посмотреть активность" href="javascript:void(0)" (click)="copy(user); loadActivity(user); openModal(userActivity);"><span style="color: blueviolet" class="glyphicon glyphicon-stats" aria-hidden="true"></span></a>&nbsp;
                            <a title="Редактировать" href="javascript:void(0)" (click)="copy(user); openModal(edit);"><span style="color: purple" class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
                            <a title="Удалить" href="javascript:void(0)" (click)="delete(user)"><span style="color: red" class="glyphicon glyphicon-remove" aria-hidden="true"></span></a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

    </tab>
    <tab heading="Журнал активности" id="tab3">
        <br />

        <div style="text-align: left">
            <input type="text"
                   class="form-control"
                   [(ngModel)]="range"
                   (ngModelChange)="loadAllActivity()"
                   AUTOCOMPLETE="off"
                   bsDaterangepicker
                   placeholder="Выбрать период"
                   style="width: 300px; float: left;"> &nbsp;
        </div>
        <br /> <br />
        <div *ngIf="allActivity && allActivity.length != 0">
            <p *ngFor="let a of allActivity">
                {{a}}
            </p>
        </div>
        <br />

        <div style="text-align: center; font-style: italic;" *ngIf="!allActivity || allActivity.length == 0"><span>Нет данных об активности пользователей за выбранный период</span></div>

    </tab>
    <tab heading="Настройка мероприятий" id="tab4">
        <br />
        <div>
            <h4>
                Список мероприятий, для которых возможно создание протоколов:
            </h4>

            <p>
                Укажите формы мероприятий, требующих оформления протоколом, для автоматического формирования порядкового номера протокола с начала календарного года
            </p>

            <div style="display: flex">
                <input type="text" [(ngModel)]="nameToAdd" class="form-control" placeholder="Добавить форму мероприятия" style="width: 350px; margin-top:20px; margin-bottom: 15px">
                <button class="btn btn-success" style="margin: 19px;" type="button" (click)="createActionName()"><span class="glyphicon glyphicon-plus"></span></button>
            </div>

            <ul style="padding-left: 15px;">
                <li *ngFor="let name of actionNames">
                    {{name}}
                    <a class="action-icons" href="javascript:void(0)" style="color: red" (click)="deleteActionName(name)">  (удалить)</a>
                </li>
            </ul>

        </div>
    </tab>
</tabset>

</div>

<ng-template #edit>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Редактировать пользователя</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Фамилия:
    <input type="text" [(ngModel)]="selectedUser.lastName" class="form-control" placeholder="Фамилия">
    <br />
    Имя:
    <input type="text" [(ngModel)]="selectedUser.firstName" class="form-control" placeholder="Имя">
    <br />
    Отчество:
    <input type="text" [(ngModel)]="selectedUser.surName" class="form-control" placeholder="Отчество">
    <br />
    Электронная почта:
    <input type="text" [(ngModel)]="selectedUser.email" class="form-control" placeholder="Электронная почта">
    <br />
    Роль пользователя в системе:
    <ng-select [items]="allRoles"
               [multiple]="false"
               [closeOnSelect]="true"
               [searchable]="false"
               bindLabel="name"
               bindValue="name"
               placeholder="Выберите одну роль"
               [(ngModel)]="selectedUser.role">
    </ng-select>
    <br />
    
    <a href="javascript:void(0)" (click)="generateNewPassword()">Сгенерировать новый пароль</a>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="saveChanges(); closeModal();" class="btn btn-success">Сохранить изменения</button>
    </div>

  </div>
</ng-template>

<ng-template #add>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Зарегистрировать нового пользователя</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Фамилия:
    <input type="text" [(ngModel)]="lastName" class="form-control" placeholder="Фамилия">
    <br />
    Имя:
    <input type="text" [(ngModel)]="firstName" class="form-control" placeholder="Имя">
    <br />
    Отчество:
    <input type="text" [(ngModel)]="surName" class="form-control" placeholder="Отчество">
    <br />
    Электронная почта:
    <input type="text" [(ngModel)]="email" class="form-control" placeholder="Электронная почта">
    <br />
    Роль пользователя в системе:
    <ng-select [items]="allRoles"
               [multiple]="false"
               [closeOnSelect]="true"
               [searchable]="false"
               bindLabel="name"
               placeholder="Выберите одну роль"
               [(ngModel)]="selectedRole">
    </ng-select>
    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="create()" class="btn btn-success">Создать</button>
    </div>

  </div>
</ng-template>

<ng-template #userActivity>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Активность пользователя {{selectedUser.fullName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div *ngIf="selectedUser.activity && selectedUser.activity.length != 0" style="overflow-y: scroll; height:400px;">
      <p *ngFor="let a of selectedUser.activity">
        {{a}}
      </p>
    </div>

    <div style="text-align: center; font-style: italic;" *ngIf="!selectedUser.activity || selectedUser.activity.length == 0"><span>Нет данных об активности</span></div>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Закрыть</button>
    </div>

  </div>
</ng-template>
