<h2>Исходящая документация</h2>

<div class="panel panel-default">
  <div class="panel-body">
      <div style="text-align: left">
          <button type="button" (click)="openModal(add)" class="btn btn-success"><span class="glyphicon glyphicon-plus"></span> Создать карточку документа</button>
      </div>
  </div>
</div>

<table class="table">
  <thead>
    <tr>
      <th scope="col">
        <a href="javascript:void(0)" (click)="sort('Num')">Номер</a>
        <input [(ngModel)]="filter.num" (ngModelChange)="loadData()" type="text" class="form-control" />
      </th>
      <th scope="col">
        <a href="javascript:void(0)" (click)="sort('Name')">Наименование</a>
        <input [(ngModel)]="filter.name" (ngModelChange)="loadData()" type="text" class="form-control" />
      </th>
      <th scope="col">
        <a href="javascript:void(0)" (click)="sort('Organization.Name')">Кому</a>
        <ng-select bindLabel="name" bindValue="id" (ngModelChange)="loadData()"
          style="max-width: 180px; min-width: 150px; font-weight: normal;">
        </ng-select>
      </th>
      <th scope="col">
        <a href="javascript:void(0)" (click)="sort('Type.Name')">Тип</a>
        <ng-select bindLabel="name" bindValue="id" (ngModelChange)="loadData()"
          style="max-width: 180px; min-width: 150px; font-weight: normal;">
        </ng-select>
      </th>
      <th scope="col">
        <a href="javascript:void(0)" (click)="sort('Taken')">Отправлено</a>
        <input [(ngModel)]="filter.taken" (ngModelChange)="loadData()" type="text" class="form-control" />
      </th>
      <th scope="col">
        <a href="javascript:void(0)" (click)="sort('Deadline')">Срок ответа</a>
        <input [(ngModel)]="filter.deadline" (ngModelChange)="loadData()" type="text" class="form-control" />
      </th>
      <th scope="col">
        <a href="javascript:void(0)" (click)="sort('Responsible')">Ответственный</a>
        <input [(ngModel)]="filter.userId" (ngModelChange)="loadData()" type="text" class="form-control" />
      </th>
      <th scope="col" style="width: 30% !important;">Дополнительно</th>
      <th scope="col">Действия</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let doc of docs">
      <td>{{doc.num}}</td>
      <td>{{doc.name}}</td>
      <td>{{doc.organization?.name}}</td>
      <td>{{doc.type}}</td>
      <td>{{doc.taken | date : 'dd.MM.yyyy' }}</td>
      <td>{{doc.deadline | date : 'dd.MM.yyyy' }}</td>
      <td>{{doc.user.fullName}}</td>
      <td style="width: 30% !important;    word-break: break-word;">
        <span (click)="openModal(fullComment, doc)" style="cursor: pointer;">
          {{getCutComment(doc.description)}}
        </span>
      </td>
      <td>
        <div class="action-icons">
          <a title="Редактировать" (click)="openModal(edit, doc)" href="javascript:void(0)" class="pi pi-pencil" aria-hidden="true"></a>
          <a title="Удалить" href="javascript:void(0)" (click)="openDeleteDocModal(del, doc.id)" class="pi pi-times" aria-hidden="true" style="color: red;"></a>
        </div>
      </td>
    </tr>
  </tbody>
</table>


<ng-template #add>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Создание карточки документа</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px">
      Номер:
      <input type="text" [(ngModel)]="newDoc.num" class="form-control" placeholder="Номер">
    </div>
    <br />
    <div style="margin-top: -10px">
      Наименование:
      <input type="text" [(ngModel)]="newDoc.name" class="form-control" placeholder="Наименование">
    </div>
    <br />
    <div style="margin-top: -10px">
      Тип:
      <input type="text" [(ngModel)]="newDoc.type" class="form-control" placeholder="Тип">
    </div>
    <br />
    Кому:
    <ng-select [items]="organizations | async" [multiple]="false" [closeOnSelect]="false" [searchable]="true"
      bindLabel="name" bindValue="id" placeholder="Вы можете сделать выбор позже" [(ngModel)]="newDoc.organizationId">
    </ng-select>
    <br />
    <div>
      Отправлено:
      <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" [(ngModel)]="newDoc.taken"
        AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
    </div>
    <br />
    <div>
      Срок выполнения:
      <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" [(ngModel)]="newDoc.deadline"
        AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
    </div>
    <br />
    Ответственный:
    <ng-select [items]="responsibles | async" [multiple]="false" [closeOnSelect]="true" [searchable]="true"
      bindLabel="fullName" bindValue="id" placeholder="Выберите одного" [(ngModel)]="newDoc.userId">
    </ng-select>
    <br />
    <div>
      <input type="checkbox" id="cto" [(ngModel)]="newDoc.createTicket">
      <label style="font-weight: normal; margin-left: 5px;" for="cto"> Создать задание </label>
    </div>
    <br />
    <div *ngIf="newDoc.createTicket">
      Также создать задания на контроль для:
      <ng-select [items]="responsibles | async" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
        bindLabel="fullName" bindValue="id" placeholder="Вы можете выбрать несколько или не выбирать" [(ngModel)]="newDoc.userIdsToCheck">
      </ng-select>
      <br />
    </div>
    <div>
      Комментарий:
      <textarea [(ngModel)]="newDoc.description" class="form-control" placeholder="Комментарий"></textarea>
    </div>
    <br />

    <div>
      <kendo-upload [saveUrl]="fileUploadUrl" [removeUrl]="fileRemoveUrl" (upload)="uploadEventHandler($event)"
        (remove)="removeEventHandler($event)" (success)="uploaded($event)">
        <kendo-upload-messages select="Добавить вложение" uploadSelectedFiles="Загрузить выбранные файлы"
          clearSelectedFiles="Удалить выбранные файлы">
        </kendo-upload-messages>
      </kendo-upload>
    </div>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="addDoc(null)" class="btn btn-success">Сохранить</button>
    </div>

  </div>
</ng-template>

<ng-template #edit>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Изменение карточки документа</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: -10px">
      Номер:
      <input type="text" [(ngModel)]="newDoc.num" class="form-control" placeholder="Номер">
    </div>
    <br />
    <div style="margin-top: -10px">
      Наименование:
      <input type="text" [(ngModel)]="newDoc.name" class="form-control" placeholder="Наименование">
    </div>
    <br />
    <div style="margin-top: -10px">
      Тип:
      <input type="text" [(ngModel)]="newDoc.type" class="form-control" placeholder="Тип">
    </div>
    <br />
    Кому:
    <ng-select [items]="organizations | async" [multiple]="false" [closeOnSelect]="false" [searchable]="true"
      bindLabel="name" bindValue="id" placeholder="Вы можете сделать выбор позже" [(ngModel)]="newDoc.organizationId">
    </ng-select>
    <br />
    <div>
      Отправлено:
      <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" [(ngModel)]="newDoc.taken"
        AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
    </div>
    <br />
    <div>
      Срок выполнения:
      <input type="text" placeholder="Щелкните здесь для выбора" class="form-control" [(ngModel)]="newDoc.deadline"
        AUTOCOMPLETE="off" [bsConfig]="bsConfig" bsDatepicker>
    </div>
    <br />
    Ответственный:
    <ng-select [items]="responsibles | async" [multiple]="false" [closeOnSelect]="false" [searchable]="true"
      bindLabel="fullName" bindValue="id" placeholder="Вы можете сделать выбор позже" [(ngModel)]="newDoc.userId">
    </ng-select>
    <br />
    <div>
      Комментарий:
      <textarea [(ngModel)]="newDoc.description" class="form-control" placeholder="Комментарий"></textarea>
    </div>
    <br />

    <div>
      <table class="table" *ngIf="newDoc.files.length != 0">
        <thead>
          <tr>
            <th scope="col" style="width: 80%">Файл</th>
            <th scope="col" style="width: 10%">Размер</th>
            <th scope="col" style="width: 10%; text-align: center">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of newDoc.files">
            <td>
              <i *ngIf="file.extension == 'doc' || file.extension == 'docx'" class="fa fa-file-word-o"
                aria-hidden="true" style="color: blue"></i>
              <i *ngIf="file.extension == 'xls' || file.extension == 'xlsx'" class="fa fa-file-excel-o"
                aria-hidden="true" style="color: green"></i>
              <i *ngIf="file.extension == 'ppt' || file.extension == 'pptx'" class="fa fa-file-powerpoint-o"
                aria-hidden="true" style="color: orange"></i>
              <i *ngIf="file.extension == 'pdf'" class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i>
              <i *ngIf="file.extension == 'zip' || file.extension == 'rar'" class="fa fa-file-archive-o"
                aria-hidden="true" style="color: dimgray"></i>
              <i *ngIf="file.extension == 'png' || file.extension == 'jpg' || file.extension == 'jpeg'"
                class="fa fa-file-image-o" aria-hidden="true" style="color: purple"></i>
              <i *ngIf="file.extension != 'doc'
                     && file.extension != 'docx'
                     && file.extension != 'xls'
                     && file.extension != 'xlsx'
                     && file.extension != 'ppt'
                     && file.extension != 'pptx'
                     && file.extension != 'pdf'
                     && file.extension != 'zip'
                     && file.extension != 'rar'
                     && file.extension != 'png'
                     && file.extension != 'jpg'
                     && file.extension != 'jpeg'" class="fa fa-file-o" aria-hidden="true"></i>
              {{file.name}}
            </td>
            <td>{{file.sizeMb}} Мб</td>
            <td style="text-align: center">
              <a title="Скачать" (click)="downloadFile(file.id)" href="javascript:void(0)"><span style="color: green"
                  class="glyphicon glyphicon-save" aria-hidden="true"></span></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <kendo-upload [saveUrl]="fileUploadUrl" [removeUrl]="fileRemoveUrl" (upload)="uploadEventHandler($event)"
        (remove)="removeEventHandler($event)" (success)="uploaded($event)">
        <kendo-upload-messages select="Добавить вложение" uploadSelectedFiles="Загрузить выбранные файлы"
          clearSelectedFiles="Удалить выбранные файлы">
        </kendo-upload-messages>
      </kendo-upload>
    </div>

    <div style="text-align:center; margin-top: 15px;">
      <button type="button" (click)="closeModal()" class="btn btn-danger">Отмена</button>
      <button type="button" (click)="editDoc(null)" class="btn btn-success">Сохранить</button>
    </div>

  </div>
</ng-template>

<ng-template #del>
  <div class="modal-header">
      <h4 class="modal-title pull-left">Удалить запись о документе?</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div style="text-align:center; margin-top: 15px;">
          <button type="button" (click)="closeModal()" class="btn btn-danger">Нет</button>
          <button type="button" (click)="deleteDoc(deleteId)" class="btn btn-success">Да</button>
      </div>
  </div>

</ng-template>

<ng-template #fullComment>
  <div class="modal-header">
      <h4 class="modal-title pull-left">Полный комментарий</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <textarea readonly class="full-comment-textarea">{{this.newDoc.description}}</textarea>
  </div>
</ng-template>

<p-toast></p-toast>